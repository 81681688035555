<style lang="sass" scoped>
.linked
  // border-bottom: solid 1px #ccc
  // font-weight: 600

</style>
<template lang="pug">
div.text-right(:key='ts')
  span(:class='{linked:hasItems}') {{value}}
  span.float-left(v-if='!hasItems' style='color: #999; margin-right: -30px')
    b-icon-exclamation-triangle-fill(style='color: #ffa801')
    |  없음
</template>

<script>

import { isString, isArray, isNumber } from 'lodash'

export default {
  name: "CellRelation",
  props: ['value', 'col', 'lookup_candidates_by_value'],
  components: {

  },
  watch: {
    // value() {
    //   this.ts = Date.now()
    // },
    // col: {
    //   handler() {
    //     this.ts = Date.now()
    //   },
    //   deep: true,
    // },
  },
  computed: {
    hasItems() {
      return this.lookup_candidates_by_value
        && this.lookup_candidates_by_value[this.value]
        && this.lookup_candidates_by_value[this.value].length > 0
    }
  },
  data() {
    return {
      ts: Date.now(),
      // invalid: false,
    }
  },
  mounted() {

  },
  methods: {

  },
};
</script>
