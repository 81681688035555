<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.menu-bar
  height: 53px
.frame
  width: calc(100vw - 300px)
  height: calc(100vh - 54px)
  overflow: scroll
.td-check
  min-width: 40px
  // padding: 7px 7px 7px 6px
  padding: 8px 5px 8px 11px //8px ​5px 8px 11px
  text-align: center
.td-card
  padding: 0.5rem
.table-project
  // width: 3000px
  border-color: #d4d4d4
  font-size: 14px
  border-width: 1px 0px 1px 0px
  th, .th
    padding: 0.5rem
    &.header
      color: #999
      font-weight: normal
      &:hover
        background-color: #f0f0f0
        cursor: pointer
  .th-check
    width: 40px
    padding: 7px 0 7px 6px
    text-align: center
  // .td-check
  //   // width: 40px
  //   // padding: 7px 7px 7px 6px
  //   padding: 8px ​5px 8px 11px
  //   text-align: center
  .td
    // width: 200px
    padding: 0.5rem
    span
      // width: inherit
      display: block
      // overflow: hidden
      // white-space: nowrap
      word-break: break-word

  .hover
    width: fit-content
    cursor: pointer
    .hover-visible
      visibility: hidden
    &:hover
      box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.5)
      .hover-visible
        visibility: visible

    &.row-selected
      background-color: mix(#007bff, #f0f0f0, 8%)

      td
        border-top-color: mix(#007bff, #f0f0f0, 20%)
        border-left-color: mix(#007bff, #f0f0f0, 20%) !important
    &.row-open
      box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.5)
  tbody
    tr
      td
        padding: 0.5rem

      &.hover
        cursor: pointer
        .hover-visible
          visibility: hidden
        &:hover
          box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.5)
          .hover-visible
            visibility: visible

        &.row-selected
          background-color: mix(#007bff, #f0f0f0, 8%)

          td
            border-top-color: mix(#007bff, #f0f0f0, 20%)
            border-left-color: mix(#007bff, #f0f0f0, 20%) !important
        &.row-open
          box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.5)
      &.row-edited
        background-color: mix(#f1c40f, #fff, 10%)

        td
          // border-top-color: mix(#f1c40f, #fff, 20%)
          // border-left-color: mix(#f1c40f, #fff, 20%) !important
        .input-cell
          background-color: mix(#f1c40f, #fff, 10%)


      .td
        overflow: hidden
        span
          width: inherit
          display: block
          overflow: hidden
          white-space: nowrap
.col-100
  max-width: 100px
  display: inline-flex
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
.col-200
  max-width: 200px
  display: inline-flex
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
.text-label
  color: #999
.search-container
  padding-top: 12px
  padding-left: 0.5rem
  // margin-top: 12px
  // margin-bottom: 1rem
  border-top: solid 1px #f0f0f0
  border-bottom: solid 1px #f0f0f0
.history-note
  background-color: mix(#f1c40f, #fff, 30%) !important
  border-bottom: solid 1px mix(#f1c40f, #fff, 40%) !important
.input-cell
  border: 0
  box-shadow: none
  transition: none
  &:focus
    background-color: #fff !important
    transform: scale(1.05) translateY(-1px)
    transition: none
    position: absolute
    // top: -1px
    width: 200px
    // width: max-content
    z-index: 10
    // height: 14rem
    // border: solid 1px rgba(0,0,0,.05) !important
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15), inset 0px 0px 0px 1px rgba(0,0,0,.1) !important
    // box-shadow: 0 1rem 3rem rgba(0,0,0,.18), inset 0px 0px 0px 1px rgba(0,0,0,.1) !important
.frame-scroll
  overflow: scroll
  &:focus-visible
    // outline: 0px solid transparent
    outline-color: transparent !important
    // ; !important
    // -webkit-box-shadow: none
    // box-shadow: none
.frame-scroll-none
  overflow: hidden !important
.pipeline-status
  border-top: solid 2px #ccc
  &.pipeline-status-on
    border-top: solid 2px #007bff !important
    color: #007bff
.pipeline-preview
  & > div:last-child
    margin: 0 !important
</style>
<template lang="pug">
div.async(:class='{done: done}' @click='blur_popover' @paste='onPaste' ref='project' autofocus @keydown.down.once='focus_scroll()' )
  .menu-bar.d-flex
    .p-2
      button.btn.btn-default#opt_document(@click.stop='$refs.opt_document.$emit("open")' type='button' ref='frame-input') {{document.name}}
      b-popover(ref='opt_document' target='opt_document' triggers='click blur' no-fade placement='bottomright' custom-class='popover-dropdown' :offset='"80px"')
        span.dropdown-label.px-2 프로젝트 이름
        .p-2
          input.form-control.form-control-sm(type='text' v-model='document.name' @blur='save_document_name')
        .mt-1(v-if='document && document.config')
          span.dropdown-label.px-2 보기 옵션
          button.btn.btn-default.btn-block.text-left.rounded-0.link.m-0(
            type='button' @click.stop='toggle_date_header("created_at")') 생성일
              span.float-right(v-show='!document.config.show_header_created_at') 표시안함
              span.float-right.text-primary(v-show='document.config.show_header_created_at') 표시
          button.btn.btn-default.btn-block.text-left.rounded-0.link.m-0(
            type='button' @click.stop='toggle_date_header("updated_at")') 최근 업데이트일
              span.float-right(v-show='!document.config.show_header_updated_at') 표시안함
              span.float-right.text-primary(v-show='document.config.show_header_updated_at') 표시
          button.btn.btn-default.btn-block.text-left.rounded-0.link.m-0(
            type='button' @click.stop='toggle_date_header("last_note_at")') 최근 코멘트일
              span.float-right(v-show='!document.config.show_header_last_note_at') 표시안함
              span.float-right.text-primary(v-show='document.config.show_header_last_note_at') 표시
          button.btn.btn-default.btn-block.text-left.rounded-0.link.m-0(
            type='button' @click.stop='toggle_date_header("last_note")') 최근 메모
              span.float-right(v-show='!document.config.show_header_last_note') 표시안함
              span.float-right.text-primary(v-show='document.config.show_header_last_note') 표시
          button.btn.btn-default.btn-block.text-left.rounded-0.link.m-0(
            type='button' @click.stop='toggle_date_header("pipeline")') 파이프라인
              span.float-right(v-show='!document.config.show_header_pipeline') 표시안함
              span.float-right.text-primary(v-show='document.config.show_header_pipeline') 표시
          button.btn.btn-default.btn-block.text-left.rounded-0.link.m-0(
            type='button' @click.stop='toggle_date_header("name")') 제목
              span.float-right(v-show='!document.config.show_header_name') 표시안함
              span.float-right.text-primary(v-show='document.config.show_header_name') 표시
          .border-top
          //- button.btn.btn-default.btn-block.text-left.rounded-0.link.m-0(
            type='button' @click.stop='toggle_hidden_header_colkey("created_at")') Created At
              b-icon-check(v-show='!document.config.headers["created_at"]')
          //- button.btn.btn-default.btn-block.text-left.rounded-0.link.m-0(
          //-   type='button' @click.stop='toggle_view_col("updated_at")') Updated At
          //-     b-icon-check(v-show='!document.config.headers["updated_at"]')
          button.btn.btn-default.btn-block.text-left.rounded-0.link.m-0(
            v-for='col in document.config.cols'
            type='button' @click.stop='toggle_hidden_header_col(col)') {{col.label}}
              span.float-right(v-show='col.hidden_header') 표시안함
              span.float-right.text-primary(v-show='!col.hidden_header') 표시
          //- span.dropdown-label.px-2 저장된 필터
          //- button.btn.btn-default.btn-block.text-left.rounded-0.link.m-0(
          //-   v-for='f in document.config.filters'
          //-   type='button' @click.stop='open_filter(f)')
          //-     b-icon-hash.mr-2.opacity-50
          //-     | {{f.name}}
          .border-top
          //- span.dropdown-label.px-2  옵션
          .p-2
            b-form-checkbox(v-model='document.config.show_header_card' value='Y' unchecked-value='N' switch @input='toggle_date_header_lazy("header_card")')
              span(v-show='document.config.show_header_card == "Y"') 카드 표시
              span(v-show='document.config.show_header_card != "Y"') 테이블 표시
            //- div(v-show='document.config.show_header_card == "Y"')
              div.clearfix(v-for='col in document.config.cols')
                b-form-checkbox.float-right(v-model='col.hidden_header_card' value='Y' unchecked-value='N' switch)
                div {{col.label}}

          //- button.btn.btn-default.btn-block.text-left.py-1.rounded-0.link.m-0.opacity-50(@click='open_filter_modal')
            b-icon-plus.mr-2.opacity-50
            | 추가

        //- .border-top
        //- button.btn.btn-default.btn-block.rounded-0.link.text-left(type='button' @click='toggle_star')
          | 즐겨찾기
        .border-top
        button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='copy_project')
          b-icon-clipboard-plus.mr-2.opacity-50
          span 프로젝트 복사
        button.btn.btn-default.btn-block.rounded-0.link.text-left.text-danger(type='button' @click='delete_document')
          | 프로젝트 삭제
      b-spinner.ml-2.async(label='가져오는 중...' small variant='secondary' :class='{done:loading}')

    div.pt-2
      template(v-if='document && document.config && document.config.filters && document.config.filters.length')
        ul.nav.nav-pills(style='font-size: 13px; font-weight: 600')
          template(v-for='(f, $index) in document.config.filters')
            li.nav-item
              a.nav-link.px-2.py-2(href='#' @click='open_filter(f)' :class='[ (current_filter.ts && current_filter.ts == f.ts) ? "active" : ""] ')
                span.col-100 {{f.name}}
            b-popover(:ref='`opt_filter_${$index}`' :target='`opt_filter_${$index}`' triggers='click ' no-fade placement='bottom' custom-class='popover-dropdown')
              span.dropdown-label.px-2 필터이름
              .p-2
                input.form-control.form-control-sm(type='text' v-model.lazy='f.name' placeholder='나의 필터')
              .px-2.pb-2
                button.btn.btn-primary.btn-block.m-0(type='button' @click='save_current_filter' :disabled='f.name.length==0') 저장
              .border-top
              button.btn.btn-default.text-danger.btn-block.text-left.m-0(type='button' @click='delete_current_filter')
                b-icon-trash.mr-2.opacity-50
                | 삭제


      modal.modal-name-filter(name='filter' transition='filter' :shiftY='0.2' :height='`auto`' :scrollable='true')
        search-filter(:document='document' @updated='filter_did_updated' :default-selected='selected_filter')

    .p-2.ml-auto
      button.btn.btn-default.opacity-50(type='button' id='opt_more_table')
        b-icon-three-dots
      button.btn.btn-default(type='button' @click='create()') 추가
      b-popover(ref='opt_more_table' target='opt_more_table' triggers='click' no-fade placement='bottom' custom-class='popover-dropdown')
        button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0.pr-4(type='button' @click='export_file("csv")')
          b-icon-file-text.mr-2.opacity-50
          span CSV 다운로드
        button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='export_file("xlsx")')
          b-icon-file-spreadsheet.mr-2.opacity-50
          span 엑셀 다운로드
        button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='export_file("html")')
          b-icon-file-code.mr-2.opacity-50
          span HTML 다운로드
        .border-bottom.opacity-50
        div(v-if='document && document.config')
          //- pre {{document.config}}
          button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click.exact='toggle_config("slack_enabled")' :disabled='loading')
            b-icon-file-code.mr-2.opacity-50
            span Slack 알림
            //- span(v-show='document.config.slack_enabled == "Y"') Slack 알림 켜짐
            //- span(v-show='document.config.slack_enabled != "Y"') Slack 알림 꺼짐
            b-form-checkbox.float-right(v-model='document.config.slack_enabled' value='Y' unchecked-value='N' switch :disabled='loading')
              span(@click.stop='' style='position: relative; top: 2px')
                span(v-show='document.config.slack_enabled == "Y"') 켜짐
                span(v-show='document.config.slack_enabled != "Y"') 꺼짐
          //- pre {{document.config.slack_enabled}}
          .px-2.pb-2(v-show='document.config.slack_enabled == "Y"')
            div(v-if='!$store.state.slack_config || $store.state.slack_config.authorized_at === null')
              .text-center 슬랙 앱 설치가 필요합니다.
              button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.mt-2.w-50(@click='connect_slack()') 열기
            div(v-else)
              //- pre {{ $store.state.slack_config }}
              div(v-if='$store.state.slack_config && $store.state.slack_config.channel_json')
                //- pre {{document.config.slack_channel_id}}
                span.dropdown-label 채널
                .d-flex.mb-1
                  select.form-control.p-1(v-model='document.config.slack_channel_id' style='height: 2rem; font-size: 12px' @blur='save_document_col()' :disabled='is_refreshing_slack')
                    optgroup(label='@noitaler 초대됨')
                      option(:value='ch.id' v-for='ch in $store.state.slack_config.channel_json' v-if='ch.is_member') \#{{ch.name}}
                        span(v-if='ch.is_private')  (Private)
                    optgroup(label='@noitaler 초대 필요함')
                      option(:value='ch.id' v-for='ch in $store.state.slack_config.channel_json' v-if='!ch.is_member') \#{{ch.name}}
                        span(v-if='ch.is_private')  (Private)
                  button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.w-50(@click='refresh_slack()' :disabled='is_refreshing_slack') 새로고침

                b-form-checkbox.py-1(v-model='document.config.slack_subscribe_note' value='Y' unchecked-value='N' switch @input='save_document_col()' :disabled='loading') 새로운 담당자 메모 알림
                b-form-checkbox.py-1(v-model='document.config.slack_subscribe_update' value='Y' unchecked-value='N' switch @input='save_document_col()' :disabled='loading') 새로운 변경내역 알림
            //- select(v-model='document.config.slack_installed)
        div(v-show='!rows_selected_count')
          .border-bottom.opacity-50
          button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='toggle_show_deleted')
            b-icon-trash.mr-2.opacity-50
            span(v-if='query_deleted === "Y"') 휴지통 닫기
            span(v-else) 휴지통 열기

      //- span {{rows_selected_count}}
      //- div(v-show='rows_selected_count') {{rows_selected_count}}


  .frame#project-frame(tabindex=0 ref='frame-scroll' :class='{"frame-scroll-none": is_editing_focusing}')
    div
      //- (v-show='filters.length > 0')
      .position-fixed.w-100.bg-white(style='top:53px; z-index: 10;')
        div.d-flex.flex-wrap.search-container(style='width: calc(100% - 300px)')
          small.text-secondary.pr-1.text-center(style='line-height: 26px; min-width: 50px')
            strong(v-if='filters.length > 0')
              | {{rows.length}}건
              | (총 {{rows_count}}건)
            strong(v-else)
              | {{rows_count}}건
          .mr-2.pb-2
            button.btn.btn-default.btn-sm.item-filter.px-2.py-1.shadow-sm.border.text-secondary(type='button' @click.stop='open_filter_modal') 검색
          div.mr-2.pb-2(v-if='filters && filters.length && tags_ready' v-for='item in filters')
            button.btn.btn-default.btn-sm.item-filter.px-2.py-1.shadow-sm.border.text-secondary(type='button' @click.stop='open_selected_filter_modal(item)')
              b-icon-person-fill(v-if='item.prekey == "search.customer"')
              span(v-if='item.prekey == "search.field"') {{item.col.label}}:&nbsp;

              span(v-if='item.key == "search.field.value_not_contain"') (제외)&nbsp;
              span(v-else-if='item.key == "search.field.value_is_empty"') (비어있음)&nbsp;
              span(v-else-if='item.key == "search.field.value_isnt_empty"') (비어있지않음)&nbsp;
              span(v-else-if='item.key == "search.field.tag_include"')
                span.tag-item.rounded.d-inline-block.mr-1(
                  v-if='document.config.cols_options[item.col.key] && document.config.cols_options[item.col.key][item.tag.id]' :style='{backgroundColor: (document.config.cols_options[item.col.key][item.tag.id].color || "#e4e4e4")}'
                ) {{document.config.cols_options[item.col.key][item.tag.id].name}}
              span(v-else-if='item.key == "search.field.tag_exclude"')
                span.tag-item.rounded.d-inline-block.mr-1(
                  v-if='document.config.cols_options[item.col.key] && document.config.cols_options[item.col.key][item.tag.id]' :style='{backgroundColor: (document.config.cols_options[item.col.key][item.tag.id].color || "#e4e4e4")}'
                ) {{document.config.cols_options[item.col.key][item.tag.id].name}}
                | 제외
              span(v-else-if='item.key == "search.row.not_in_ids"') (고객아이디 제외)&nbsp;
              span(v-else-if='item.key == "search.row.in_ids"') (고객아이디 지정)&nbsp;
              span(v-else-if='item.prekey == "search.row"') {{item.value.length}}건&nbsp;
              span(v-else) {{item.value}}
              b-icon-x.item-clear(@click.prevent.stop='clear_filter(item)')
            template(v-for='subitem in item.sub_or')
              button.btn.btn-default.btn-sm.item-filter.px-2.py-1.shadow-sm.border.text-secondary(type='button' @click.stop='open_selected_filter_modal(subitem)')
                b-icon-person-fill(v-if='subitem.prekey == "search.customer"')
                span(v-if='subitem.prekey == "search.field"') {{subitem.col.label}}:&nbsp;

                span(v-if='subitem.key == "search.field.value_not_contain"') (제외)&nbsp;
                span(v-else-if='subitem.key == "search.field.value_is_empty"') (비어있음)&nbsp;
                span(v-else-if='subitem.key == "search.field.value_isnt_empty"') (비어있지않음)&nbsp;
                span(v-else-if='subitem.key == "search.field.tag_include"')
                  span.tag-item.rounded.d-inline-block.mr-1(
                    v-if='document.config.cols_options[subitem.col.key] && document.config.cols_options[subitem.col.key][subitem.tag.id]' :style='{backgroundColor: (document.config.cols_options[subitem.col.key][subitem.tag.id].color || "#e4e4e4")}'
                  ) {{document.config.cols_options[subitem.col.key][subitem.tag.id].name}}
                span(v-else-if='subitem.key == "search.field.tag_exclude"')
                  span.tag-item.rounded.d-inline-block.mr-1(
                    v-if='document.config.cols_options[subitem.col.key] && document.config.cols_options[subitem.col.key][subitem.tag.id]' :style='{backgroundColor: (document.config.cols_options[subitem.col.key][subitem.tag.id].color || "#e4e4e4")}'
                  ) {{document.config.cols_options[subitem.col.key][subitem.tag.id].name}}
                  | 제외
                span(v-else-if='subitem.key == "search.row.not_in_ids"') (고객아이디 제외)&nbsp;
                span(v-else-if='subitem.key == "search.row.in_ids"') (고객아이디 지정)&nbsp;
                span(v-else-if='subitem.prekey == "search.row"') {{subitem.value.length}}건&nbsp;
                span(v-else-if='!subitem.key') (없음)
                span(v-else) {{subitem.value}}
                b-icon-x.item-clear(@click.prevent.stop='clear_filter(subitem)')
            button.btn.btn-default.btn-sm.item-filter.px-2.py-1.shadow-sm.border.text-secondary.opacity-50(type='button' @click.stop='or_filter(item)') 또는
          .ml-auto
            template(v-if='document && document.config && document.config.filters && document.config.filters.length')
              template(v-for='(f, $index) in document.config.filters')
                button.btn.btn-default.text-secondary(type='button' v-show='current_filter.ts && current_filter.ts == f.ts' :id='`opt_filter_${$index}`' @click.stop='' style='margin-top: -5px; margin-right: 5px') 수정
            button.btn.btn-default.text-secondary(type='button' v-show='filters.length' @click='clear_current_filter' style='margin-top: -5px; margin-right: 5px') 검색 취소
            button.btn.btn-default.text-primary(type='button' v-show='filters.length' id='opt_filter_add' @click.stop='' style='margin-top: -5px; margin-right: 5px')
              b-icon-plus.opacity-50
              | 필터
            b-popover(ref='opt_filter_add' target='opt_filter_add' triggers='click blur' no-fade placement='bottom' custom-class='popover-dropdown')
              span.dropdown-label.px-2 필터이름
              .p-2
                input.form-control.form-control-sm(type='text' v-model='new_filter_name' placeholder='나의 필터')
              .border-top
              .p-2
                button.btn.btn-primary.btn-block(type='button' @click='save_filter' :disabled='new_filter_name.length==0') 검색결과를 상단에 고정
      div(style='margin-bottom: 50px')


    modal.py-2(name='excel' width='700px' :height='`auto`' :scrollable='true')
      button.btn.btn-default.float-right.text-muted.rounded-0.p-4(type='button' @click='$modal.hide("excel")' style='font-size: 1.5rem')
        b-icon-x
      .p-4.bg-light
        strong.title 엑셀 가져오기
      .p-4
        excel-import(:property_id='property.id' :document_id='document.id' :document='document' @did_saved='did_saved_excel')
    //- div.pt-4(style='padding-left: calc(33vw - 300px)')
    div.mx-4.p-3.text-center.bg-light.w-100(style='color: #888;' v-show='query_deleted === "Y"')
      strong.title 삭제된 내역입니다.
      button.btn.btn-default.border.btn-sm.bg-white.ml-2(type='button' @click='toggle_show_deleted' style='margin-top: -5px') 닫기
    //- div.pt-2(style='padding-left: max(30px, calc(33vw - 450px));')
    //- div.pt-2
    div
      div.d-flex.table-project.border-top.position-sticky.bg-white.border-bottom(v-if='cols_ready' style='margin-top: -1px; width: max-content; top:49px; z-index: 10; margin-bottom: -1px')
        div.th-check(@click.prevent.stop='select_all')
          label.m-0(@click.prevent.stop='select_all')
            b-form-checkbox.rounded-0(:checked='selected_all' value='true' unchecked-value='false')
        div.border-left.header.handle.th(v-if='query_deleted === "Y"' style='width: 200px') 삭제일
        div.border-left.header.handle.th(v-if='document.config.show_header_created_at' style='width: 100px' :id='`opt_field_table_CREATED_AT`') 생성일
          span(v-show='sortby == "created_at" && sort == "ASC"')
            b-icon-arrow-down.mr-2
          span(v-show='sortby == "created_at" && sort == "DESC"')
            b-icon-arrow-up.mr-2
          b-popover(:ref='`opt_field_table_CREATED_AT`' :target='`opt_field_table_CREATED_AT`' triggers='click' no-fade placement='right' custom-class='popover-dropdown')
            div
              span.dropdown-label.px-2 정렬
              button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0.py-1(type='button' @click='change_sort_col("created_at", "ASC")' :class='{"font-weight-bold text-primary bg-light": (sortby == "created_at" && sort == "ASC")}')
                b-icon-sort-down-alt.mr-2.opacity-50
                | A → Z
              button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0.py-1(type='button' @click='change_sort_col("created_at", "DESC")' :class='{"font-weight-bold text-primary bg-light": (sortby == "created_at" && sort == "DESC")}')
                b-icon-sort-up.mr-2.opacity-50
                | Z → A
        div.border-left.header.handle.th(v-if='document.config.show_header_updated_at' style='width: 110px' :id='`opt_field_table_UPDATED_AT`') 최근 업데이트
          span.text-secondary(v-show='sortby == "updated_at" && sort == "ASC"')
            b-icon-arrow-down
          span.text-secondary(v-show='sortby == "updated_at" && sort == "DESC"')
            b-icon-arrow-up
          b-popover(:ref='`opt_field_table_UPDATED_AT`' :target='`opt_field_table_UPDATED_AT`' triggers='click' no-fade placement='right' custom-class='popover-dropdown')
            div
              span.dropdown-label.px-2 정렬
              button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0.py-1(type='button' @click='change_sort_col("updated_at", "ASC")' :class='{"font-weight-bold text-primary bg-light": (sortby == "updated_at" && sort == "ASC")}')
                b-icon-sort-down-alt.mr-2.opacity-50
                | A → Z
              button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0.py-1(type='button' @click='change_sort_col("updated_at", "DESC")' :class='{"font-weight-bold text-primary bg-light": (sortby == "updated_at" && sort == "DESC")}')
                b-icon-sort-up.mr-2.opacity-50
                | Z → A
        div.border-left.header.handle.th(v-if='document.config.show_header_last_note_at' style='width: 110px' :id='`opt_field_table_LAST_NOTE_AT`') 최근 코멘트
          span.text-secondary(v-show='sortby == "last_note_at" && sort == "ASC"')
            b-icon-arrow-down.mr-2
          span.text-secondary(v-show='sortby == "last_note_at" && sort == "DESC"')
            b-icon-arrow-up.mr-2
          b-popover(:ref='`opt_field_table_LAST_NOTE_AT`' :target='`opt_field_table_LAST_NOTE_AT`' triggers='click' no-fade placement='right' custom-class='popover-dropdown')
            div
              span.dropdown-label.px-2 정렬
              button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0.py-1(type='button' @click='change_sort_col("last_note_at", "ASC")' :class='{"font-weight-bold text-primary bg-light": (sortby == "last_note_at" && sort == "ASC")}')
                b-icon-sort-down-alt.mr-2.opacity-50
                | A → Z
              button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0.py-1(type='button' @click='change_sort_col("last_note_at", "DESC")' :class='{"font-weight-bold text-primary bg-light": (sortby == "last_note_at" && sort == "DESC")}')
                b-icon-sort-up.mr-2.opacity-50
                | Z → A
        div.border-left.header.handle.th(v-if='document.config.show_header_last_note' style='width: 200px') 최근 메모
        div.border-left.header.handle.th(v-if='document.config.show_header_pipeline' style='width: 200px;') 파이프라인
        div.border-left.header.handle.th(v-if='document.config.show_header_name' style='width: 200px;') 제목
        draggable(:class='{dragging: drag}' v-model='document.config.cols' handle='.handle' v-bind='drag_options' @start='drag = true' @end='drag_end')
          transition-group.d-flex(type='transition' :name="!drag ? 'flip-list' : null")
            div.border-left.header.handle.th(:key='col.key' v-if='!col.hidden_header && document.config.show_header_card != "Y"' v-for='col in document.config.cols' :style='{width: (col.width || 200)+"px"}' :id='`opt_field_table_${col.key}`' @click.stop='$root.$emit("bv::hide::popover"); $root.$emit("bv::show::popover", `opt_field_table_${col.key}`)')
              //- span(v-if='col.format == `text`')
              //-   b-icon-fonts.mr-1
              //- span(v-else-if='col.format == `textarea`')
              //-   b-icon-text-left.mr-1
              //- span(v-else-if='col.format == `number`')
              //-   b-icon-hash.mr-1
              //- span(v-else-if='col.format == `check`')
              //-   b-icon-check-square.mr-1
              //- span(v-else-if='col.format == `select` && col.max')
              //-   b-icon-caret-down-fill.mr-1
              //- span(v-else-if='col.format == `select` && !col.max')
              //-   b-icon-list-ul.mr-1
              //- span(v-else-if='col.format == `@고객이름`')
              //-   b-icon-type.mr-1
              //- span(v-else-if='col.format == `@고객아이디`')
              //-   b-icon-person-bounding-box.mr-1
              //- span(v-else-if='col.format == `@고객이메일`')
              //-   b-icon-at.mr-1
              //- span(v-else-if='col.format == `@고객연락처`')
              //-   b-icon-telephone-fill.mr-1
              | {{col.label}}
              span.text-secondary(v-show='sortby == col.key && sort == "ASC"')
                b-icon-arrow-down.mr-2
              span.text-secondary(v-show='sortby == col.key && sort == "DESC"')
                b-icon-arrow-up.mr-2
              b-popover(:ref='`opt_field_table_${col.key}`' :target='`opt_field_table_${col.key}`' triggers='click' no-fade placement='right' custom-class='popover-dropdown')
                div
                  .p-2
                    input.form-control.form-control-sm(type='text' v-model.lazy='col.label' @change='save_document_col' :ref='`opt_field_input_table_${col.key}`')
                  span.dropdown-label.px-2 가로폭 순서
                  .px-2.pb-2
                    .float-left(style='height: 27px')
                      input.form-control.form-control-sm(type='number' min=100 max=1000 step=50 v-model.lazy='col.width' @blur='save_document_col')
                      //- button.btn.btn-default.btn-sm.border.py-0(v-b-tooltip.hover title='칸 줄이기' @click='col_shrink(col)')
                      //-   b-icon-dash
                      //- button.btn.btn-default.btn-sm.border.py-0(v-b-tooltip.hover title='칸 키우기' @click='col_grow(col)')
                      //-   b-icon-plus
                      //- button.btn.btn-default.btn-sm.border.py-0(v-b-tooltip.hover title='왼쪽으로 이동' @click='col_left(col)')
                      //-   //- b-icon-dash
                      //-   b-icon-arrow-bar-left
                      //- button.btn.btn-default.btn-sm.border.py-0(v-b-tooltip.hover title='오른쪽으로 이동' @click='col_right(col)')
                      //-   //- b-icon-plus
                      //-   b-icon-arrow-bar-right
                    .btn-group.float-right(style='height: 27px')
                      //- button.btn.btn-default.btn-sm.border.py-0(v-b-tooltip.hover title='칸 줄이기' @click='col_shrink(col)')
                      //-   b-icon-dash
                      //- button.btn.btn-default.btn-sm.border.py-0(v-b-tooltip.hover title='칸 키우기' @click='col_grow(col)')
                      //-   b-icon-plus
                      button.btn.btn-default.btn-sm.border.py-0(v-b-tooltip.hover title='왼쪽으로 이동' @click='col_left(col)')
                        //- b-icon-dash
                        b-icon-arrow-bar-left
                      button.btn.btn-default.btn-sm.border.py-0(v-b-tooltip.hover title='오른쪽으로 이동' @click='col_right(col)')
                        //- b-icon-plus
                        b-icon-arrow-bar-right
                  .clearfix.pb-2
                  span.dropdown-label.px-2 정렬
                  button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0.py-1(type='button' @click='change_sort_col(col.key, "ASC")' :class='{"font-weight-bold text-primary bg-light": (sortby == col.key && sort == "ASC")}')
                    b-icon-sort-down-alt.mr-2.opacity-50
                    | A → Z
                  button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0.py-1(type='button' @click='change_sort_col(col.key, "DESC")' :class='{"font-weight-bold text-primary bg-light": (sortby == col.key && sort == "DESC")}')
                    b-icon-sort-up-alt.mr-2.opacity-50
                    | Z → A
                  .mt-2
                  span.dropdown-label.px-2 필드 타입
                  .px-2.pt-2(v-show='col.format == "relation" ')
                    button.btn.btn-default.btn-block.py-1.border.mb-0.text-left(:class='[col.document_id ? "text-primary bg-light":"text-secondary font-weight-bold bg-light text-center"]' type='button' @click='open_config_relation(col)')
                      span(v-if='!col.document_id') 다른 시트를 선택해주세요.
                      span(v-else)
                        span(v-if='$store.state.documents_by_id[col.document_id]') {{$store.state.documents_by_id[col.document_id].name}}
                          span.mx-1 /
                          span(v-if='selected.key == col.document_colkey' v-for='selected in $store.state.documents_by_id[col.document_id].config.cols') {{selected.label}}
                  button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' :id='`opt_field_table_sub_${col.key}`')
                    span(v-if='col.format == `text`')
                      b-icon-fonts.mr-2.opacity-50
                      span 텍스트
                    span(v-else-if='col.format == `textarea`')
                      b-icon-text-left.mr-2.opacity-50
                      span 긴 텍스트
                    span(v-else-if='col.format == `number`')
                      b-icon-hash.mr-2.opacity-50
                      span 숫자
                    span(v-else-if='col.format == `check`')
                      b-icon-check-square.mr-2.opacity-50
                      span 체크
                    span(v-else-if='col.format == `select` && col.max')
                      b-icon-caret-down-fill.mr-2.opacity-50
                      span 선택
                    span(v-else-if='col.format == `select` && !col.max')
                      b-icon-list-ul.mr-2.opacity-50
                      span 다중 선택
                    span(v-else-if='col.format == `date`')
                      b-icon-calendar-date.mr-2.opacity-50
                      span 날짜
                    span(v-else-if='col.format == `link`')
                      b-icon-link.mr-2.opacity-50
                      span URL 링크
                    span(v-else-if='col.format == `id`')
                      small.mr-2.opacity-50 ID
                      span ID 고유번호 (중복체크)
                    span(v-else-if='col.format == `relation`')
                      small.mr-2.opacity-50 다
                      span 다른시트 넣기 (연결)
                    span(v-else-if='col.format == `@고객이름`')
                      b-icon-type.mr-2.opacity-50
                      span 고객이름
                    span(v-else-if='col.format == `@고객아이디`')
                      b-icon-person-bounding-box.mr-2.opacity-50
                      span 고객아이디
                    span(v-else-if='col.format == `@고객이메일`')
                      b-icon-at.mr-2.opacity-50
                      span 고객이메일
                    span(v-else-if='col.format == `@고객연락처`')
                      b-icon-telephone-fill.mr-2.opacity-50
                      span 고객전화번호
                    span(v-else)
                      span {{col.format}}
                    small: b-icon-caret-down-fill.float-right.opacity-50
                  .px-2.pb-2(v-show='col.format == "check" ')
                    input.form-control.form-control-sm(type='text' v-model.lazy='col.description' @change='save_document_col' placeholder='설명')
                  b-popover(:ref='`opt_field_table_sub_${col.key}`' :target='`opt_field_table_sub_${col.key}`' triggers='hover blur' placement='bottom' custom-class='popover-dropdown')
                    div
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format_document_col(col, "text")' :disabled='updating_format')
                        b-icon-fonts.mr-2.opacity-50
                        span 텍스트
                        b-icon-check(v-show='col.format == `text`')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format_document_col(col, "textarea")' :disabled='updating_format')
                        b-icon-text-left.mr-2.opacity-50
                        span 긴 텍스트
                        b-icon-check(v-show='col.format == `textarea`')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format_document_col(col, "number")' :disabled='updating_format')
                        b-icon-hash.mr-2.opacity-50
                        span 숫자
                        b-icon-check(v-show='col.format == `number`')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format_document_col(col, "check")' :disabled='updating_format')
                        b-icon-check-square.mr-2.opacity-50
                        span 체크
                        b-icon-check(v-show='col.format == `check`')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format_document_col(col, "select", "max=1")' :disabled='updating_format')
                        b-icon-caret-down-fill.mr-2.opacity-50
                        span 선택
                        b-icon-check(v-show='col.format == `select` && col.max')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format_document_col(col, "select")' :disabled='updating_format')
                        b-icon-list-ul.mr-2.opacity-50
                        span 다중 선택
                        b-icon-check(v-show='col.format == `select` && !col.max')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format_document_col(col, "date")' :disabled='updating_format')
                        b-icon-calendar-date.mr-2.opacity-50
                        span 날짜
                        b-icon-check(v-show='col.format == `date`')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format_document_col(col, "link")' :disabled='updating_format')
                        b-icon-link.mr-2.opacity-50
                        span URL 링크
                        b-icon-check(v-show='col.format == `link`')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format_document_col(col, "id")' :disabled='updating_format')
                        small.mr-2.opacity-50 ID
                        span ID 고유번호 (중복체크)
                        b-icon-check(v-show='col.format == `id`')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format_document_col(col, "relation")' :disabled='updating_format')
                        small.mr-2.opacity-50 다
                        span 다른시트 넣기 (연결)
                        b-icon-check(v-show='col.format == `relation`')
                      .border-bottom.opacity-50.mb-2
                      span.dropdown-label.px-2.pt-2 고객
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format_document_col(col, "@고객이름")' :disabled='updating_format')
                        b-icon-type.mr-2.opacity-50
                        span 이름
                        b-icon-check(v-show='col.format == `@고객이름`')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format_document_col(col, "@고객아이디")' :disabled='updating_format')
                        b-icon-person-bounding-box.mr-2.opacity-50
                        span 아이디
                        b-icon-check(v-show='col.format == `@고객아이디`')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format_document_col(col, "@고객이메일")' :disabled='updating_format')
                        b-icon-at.mr-2.opacity-50
                        span 이메일
                        b-icon-check(v-show='col.format == `@고객이메일`')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format_document_col(col, "@고객연락처")' :disabled='updating_format')
                        b-icon-telephone-fill.mr-2.opacity-50
                        span 전화번호
                        b-icon-check(v-show='col.format == `@고객연락처`')

                .border-top.mt-1
                button.btn.btn-default.btn-block.rounded-0.link.text-left.text-danger(type='button' @click='delete_document_col(col)') 항목 삭제
              span.float-right
                span(v-if='col.format == `text`')
                  b-icon-fonts
                span(v-else-if='col.format == `textarea`')
                  b-icon-text-left
                span(v-else-if='col.format == `number`')
                  b-icon-hash
                span(v-else-if='col.format == `check`')
                  b-icon-check-square
                span(v-else-if='col.format == `select` && col.max')
                  b-icon-caret-down-fill
                span(v-else-if='col.format == `select` && !col.max')
                  b-icon-list-ul
                span(v-else-if='col.format == `date`')
                  b-icon-calendar-date
                span(v-else-if='col.format == `link`')
                  b-icon-link
                span(v-else-if='col.format == `id`')
                  small ID
                span(v-else-if='col.format == `relation`')
                  small 다
                span(v-else-if='col.format == `@고객이름`')
                  b-icon-type
                span(v-else-if='col.format == `@고객아이디`')
                  b-icon-person-bounding-box
                span(v-else-if='col.format == `@고객이메일`')
                  b-icon-at
                span(v-else-if='col.format == `@고객연락처`')
                  b-icon-telephone-fill
        div.border-left.header.handle.th(v-if='document.config.show_header_card == "Y"' style='width: 100vw; max-width: calc(100vw - 370px);') 내용
        div.th.border-left.header(style='width: 30px' @click='add_document_col')
          b-icon-plus
        //- div.th.bg-white.border-top(style='width: 200px; margin: -1px; 0px;' v-if='document.config.show_header_card !== "Y"')
        //- div.th.bg-white(style='width: 200px;' v-if='document.config.show_header_card !== "Y"')

      //- style='width: max-content'
      //- table.table.table-project.border-bottom(v-if='cols_ready' :style='{width: (document.config.show_header_card == "Y" ? "100%" : "max-content")}')
      //- .table.table-project(v-if='!is_editing && cols_ready && rows_ready' :style='{width: (document.config.show_header_card == "Y" ? "100%" : "max-content")}')
      .table.table-project(v-if='!is_editing && cols_ready && rows_ready' style='min-width: max-content')
        .d-flex.flex-column
          //- tr.hover(:key='row.id' v-for='row in rows' :class='{"row-selected": row.is_selected}' @click.keydown.meta.prevent.stop='open_record_new(row)' @click.keydown.ctrl.prevent.stop='open_record_new(row)' @click.exact='open_record(row)' )
          .hover.d-flex.flex-row(:key='row._key' v-for='row in rows' :class='{"row-selected": row.is_selected, "row-open": (row.id == document_record_id)}' @click.keydown.meta.prevent.stop='open_record_new(row)' @click.keydown.ctrl.prevent.stop='open_record_new(row)' @click.exact='open_record(row)' )
            div.border-bottom.td-check(@click.prevent.stop='select_row(row)')
              //- input(type='checkbox')
              b-form-checkbox(v-model='row.is_selected' value='true' unchecked-value='false' @click.prevent.stop='')
            div.border-bottom.td.border-left(v-if='query_deleted === "Y"' style='min-width: 200px; max-width: 200px') {{row.deleted_at | datetime}}
            div.border-bottom.td.border-left(v-if='document.config.show_header_created_at' style='min-width: 100px; max-width: 100px;') {{row.created_at | date}}
            div.border-bottom.td.border-left(v-if='document.config.show_header_updated_at' style='min-width: 110px; max-width: 110px;') {{row.updated_at | date}}
            div.border-bottom.td.border-left(v-if='document.config.show_header_last_note_at' style='min-width: 110px; max-width: 110px;') {{row.last_note_at | date}}
            div.border-bottom.td.border-left(v-if='document.config.show_header_last_note' style='min-width: 200px; max-width: 200px;' :id='`last_note_${row.id}`')
              div(v-if='row.last_note && row.last_note.id' style='font-size: 12px; word-break: break-word; line-height: 1rem; max-height: 5rem; overflow: hidden;') {{ row.last_note.json.note }}
                //- template(v-if='log.json.date && log.json.date.date')
                  span.bg-light.border.border-secondary.p-1.rounded.shadow-sm.text-dark
                    b-icon-calendar-date.text-danger.mr-1.opacity-50
                    strong {{log.json.date.date | localdate}}
                    span.ml-1(v-show='log.json.date.use_time == "Y"') {{log.json.date.time | time}}
              //- .opacity-50
                small {{log.username}}
                small.ml-3 {{ log.created_at | datetime_dow }}
              .opacity-50.d-flex
                small {{row.last_note.username}}
              b-popover(:target='`last_note_${row.id}`' triggers='hover blur' placement='right' :disabled='!row.last_note.id'
                custom-class='popover-dropdown popover-dropdown-sm history-note')
                div.p-3(v-if='row.last_note && row.last_note.id' style='font-size: 14px; word-break: break-word; white-space: pre-wrap; width: 280px;') {{ row.last_note.json.note }}
                .p-3.opacity-50.d-flex(style='width: 280px')
                  span {{row.last_note.username}}
                  span.ml-auto {{ row.last_note.created_at | datetime_dow }}
            div.border-bottom.td.border-left.pipeline-preview(v-if='document.config.show_header_pipeline' style='min-width: 200px')
              div.clearfix.border-bottom.mb-3(v-for='p in row.pipelines' v-if='$store.state.pipelines_by_id && $store.state.pipelines_by_id[p.pipeline_id] && $store.state.pipelines_by_id[p.pipeline_id].config')
                //- @click.stop.prevent=''
                //- style='cursor: initial'

                //- small.text-muted.d-block {{ $store.state.pipelines_by_id[p.pipeline_id].name }} - {{p.state.name}}
                .d-flex.flex-wrap
                  small.text-muted.d-block {{ $store.state.pipelines_by_id[p.pipeline_id].name }}
                  small.ml-auto.text-dark.mr-1(v-for='s in $store.state.pipelines_by_id[p.pipeline_id].config.status' v-if='s.id == p.state.id')  {{s.name}}
                .w-100.d-flex
                  span.pipeline-status.w-100(v-for='s in $store.state.pipelines_by_id[p.pipeline_id].config.status'
                    :class='{"pipeline-status-on": (s.id == p.state.id)}'
                  )
                  //- style='width: 1rem'

            div.border-bottom.td.border-left(v-if='document.config.show_header_name' style='min-width: 200px') {{row.name_text}}

            //- v-if='!col.hidden_header'
            div.border-bottom.td.border-left(
              v-if='!col.hidden_header && document.config.show_header_card != "Y"'
              v-for='col in document.config.cols'
              :style='{width: (col.width || 200)+"px", maxWidth: (col.width || 200)+"px"}'
            )
              //- pre {{row.json[col.key]}}
              template(v-if='col.format == "select"')
                cell-tag(
                  v-if='document.config.cols_options && document.config.cols_options[col.key]'
                  v-model='row.json[col.key]' :col='col' :cols_options='document.config.cols_options[col.key]')
              template(v-else-if='col.format == "date"')
                cell-date(v-model='row.json[col.key]' :col='col')
              template(v-else-if='col.format == "number"')
                cell-number(v-model='row.json[col.key]' :col='col')
              template(v-else-if='col.format == "relation"')
                cell-relation(v-model='row.json[col.key]' :col='col' :lookup_candidates_by_value='lookup_candidates_by_value')
              template(v-else-if='col.format == "link"')
                a.btn.btn-default.btn-sm.shadow-sm.border.px-2(v-if='row.json[col.key]' :href='row.json[col.key]' v-b-tooltip.hover="row.json[col.key]" target='_blank' @click.stop='')
                  | 새 탭으로 열기
                  small.ml-2.opacity-50: b-icon-box-arrow-up-right
                span.opacity-50(v-else) -
              span(v-else) {{row.json[col.key]}}
              //- .text-right(v-if='col.format == "number"') {{row.json[col.key] | number}}
              //- div(v-else-if='col.format == "date"')
              //-   template(v-if='row.json[col.key] && row.json[col.key].date')
              //-     .d-flex.flex-wrap
              //-       span
              //-         span {{row.json[col.key].date | date}}
              //-         span.ml-1(v-show='row.json[col.key].use_time == "Y"') {{row.json[col.key].time | time}}
              //-       span.mx-1.opacity-50(v-show='row.json[col.key].use_end_date == "Y"') →
              //-       span(v-show='row.json[col.key].date != row.json[col.key].end_date')
              //-         span {{row.json[col.key].end_date | date}}
              //-         span.ml-1(v-show='row.json[col.key].use_time == "Y"') {{row.json[col.key].end_time | time}}
              //- div(v-else-if='col.format == "select"')
              //-   span.tag-item.rounded.mr-1.mb-1.d-inline-block(
              //-     v-if='tags_ready && document.config.cols_options[col.key] && document.config.cols_options[col.key][tag_id]'
              //-     v-for='tag_id in row.json[col.key]' :key='tag_id' :style='{backgroundColor: (document.config.cols_options[col.key][tag_id].color || "#e4e4e4")}'
              //-   ) {{document.config.cols_options[col.key][tag_id].name}}
              //- div(v-else-if='col.format == "link"')
              //-   a.btn.btn-default.btn-sm.shadow-sm.border.px-2(v-if='row.json[col.key]' :href='row.json[col.key]' v-b-tooltip.hover="row.json[col.key]" target='_blank' @click.stop='' style='margin-top: -3px')
              //-     | 새 탭으로 열기
              //-     small.ml-2.opacity-50: b-icon-box-arrow-up-right
              //- span(v-else) {{row.json[col.key]}}
            //- td.border-left(v-if='document.config.show_header_card == "Y"' style='width: calc(50vw - 200px)')
            div.border-bottom.border-left.td-card(v-if='document.config.show_header_card == "Y"' style='')
              .d-flex.flex-wrap(style='width: 100vw; max-width: calc(100vw - 387px);')
                div.pr-2(v-for='col in document.config.cols' v-if='!col.hidden_header' style='min-width: 120px; padding-bottom: 0.5rem')
                  small.text-muted.d-block {{col.label}}
                  div.text-break(v-if='col.format == "number"') {{row.json[col.key] | number}}
                  div(v-else-if='col.format == "date"')
                    template(v-if='row.json[col.key] && row.json[col.key].date')
                      .d-flex.flex-wrap
                        span
                          span {{row.json[col.key].date | date}}
                          span.ml-1(v-show='row.json[col.key].use_time == "Y"') {{row.json[col.key].time | time}}
                        span.mx-1.opacity-50(v-show='row.json[col.key].use_end_date == "Y"') →
                        span(v-show='row.json[col.key].date != row.json[col.key].end_date')
                          span {{row.json[col.key].end_date | date}}
                          span.ml-1(v-show='row.json[col.key].use_time == "Y"') {{row.json[col.key].end_time | time}}
                    span.opacity-50(v-else) -
                  div(v-else-if='col.format == "select"')
                    template(v-if='row.json[col.key] && isArray(row.json[col.key]) && row.json[col.key].length > 0')
                      span.tag-item.rounded.mr-1.mb-1.d-inline-block(
                        v-if='tags_ready && document.config.cols_options[col.key] && document.config.cols_options[col.key][tag_id]'
                        v-for='tag_id in row.json[col.key]' :key='tag_id' :style='{backgroundColor: (document.config.cols_options[col.key][tag_id].color || "#e4e4e4")}'
                      ) {{document.config.cols_options[col.key][tag_id].name}}
                    span.opacity-50(v-else-if='row.json[col.key] && isArray(row.json[col.key]) && row.json[col.key].length === 0') -
                    span.opacity-50(v-else) -
                  div(v-else-if='col.format == "link"')
                    a.btn.btn-default.btn-sm.shadow-sm.border.px-2(v-if='row.json[col.key]' :href='row.json[col.key]' v-b-tooltip.hover="row.json[col.key]" target='_blank' @click.stop='')
                      | 새 탭으로 열기
                      small.ml-2.opacity-50: b-icon-box-arrow-up-right
                    span.opacity-50(v-else) -
                  span.text-break(v-else-if='row.json[col.key]') {{row.json[col.key]}}
                  span.opacity-50(v-else) -
                  //- div(v-if='col.format == "select"')
                  //-   span.tag-item.rounded.mr-1.mb-1.d-inline-block(
                  //-     v-if='row.json[col.key] && isArray(row.json[col.key]) && document.config.cols_options[col.key] && document.config.cols_options[col.key][tag_id]'
                  //-     v-for='tag_id in row.json[col.key]' :key='tag_id' :style='{backgroundColor: (document.config.cols_options[col.key][tag_id].color || "#e4e4e4")}'
                  //-   ) {{document.config.cols_options[col.key][tag_id].name}}
                  //- span.text-break(v-else) {{row.json[col.key] || '-'}}
            div.border-bottom.td.border-left(style='width: 30px')
          div.bg-light.d-flex(v-if='(rows_pagable_count - rows.length) > 0')
            div.td-check
            div.td(colspan='100')
              //- button.btn.btn-default.bg-light.text-secondary(type='button' @click='create')
              //- button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.text-secondary(type='button' @click='load_more')
              button.btn.btn-default.btn-sm.px-2.py-1.text-secondary(type='button' @click='load_more()' style='width: calc(100vw - 500px)')
                b-icon-three-dots.mr-2
                | 더 보기 ({{rows_pagable_count - rows.length}}건 남음)
          div.d-flex
            div.td-check
            div.td
              //- button.btn.btn-default.bg-light.text-secondary(type='button' @click='create')
              button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.text-secondary(type='button' @click='create')
                b-icon-plus.mr-1
                | 추가
              button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.text-secondary.ml-1.float-right(type='button' @click='open_edit' style='opacity:0.1; position: fixed; bottom: 0px; left:0px')
                | 편집

      //- table.table.table-project(v-if='is_editing && cols_ready' style='width: max-content')
        tbody
          //- tr(:key='row.id' v-for='row in rows' :class='{"row-edited": row.is_edited}')
          tr(:key='row._key' v-for='row in rows' :class='{"row-edited": row.is_edited}')
            td.td-check
              //- input(type='checkbox')
              //- b-form-checkbox(v-model='row.is_selected' value='true' unchecked-value='false' @click.prevent.stop='')
            td.td.border-left(v-if='query_deleted === "Y"' style='width: 200px') {{row.deleted_at | datetime}}
            td.td.border-left(v-if='document.config.show_header_created_at' style='width: 100px') {{row.created_at | date}}
            td.td.border-left(v-if='document.config.show_header_updated_at' style='width: 110px') {{row.updated_at | date}}
            td.td.border-left(v-if='document.config.show_header_last_note_at' style='width: 110px') {{row.last_note_at | date}}
            td.td.border-left(v-if='document.config.show_header_last_note' style='width: 200px' :id='`last_note_${row.id}`')
              div(v-if='row.last_note && row.last_note.id' style='font-size: 12px; word-break: break-word; line-height: 1rem; max-height: 5rem; overflow: hidden;') {{ row.last_note.json.note }}
                //- template(v-if='log.json.date && log.json.date.date')
                  span.bg-light.border.border-secondary.p-1.rounded.shadow-sm.text-dark
                    b-icon-calendar-date.text-danger.mr-1.opacity-50
                    strong {{log.json.date.date | localdate}}
                    span.ml-1(v-show='log.json.date.use_time == "Y"') {{log.json.date.time | time}}
              //- .opacity-50
                small {{log.username}}
                small.ml-3 {{ log.created_at | datetime_dow }}
              .opacity-50.d-flex
                small {{row.last_note.username}}

              b-popover(:target='`last_note_${row.id}`' triggers='hover blur' placement='right' :disabled='!row.last_note.id'
                custom-class='popover-dropdown popover-dropdown-sm history-note')
                div.p-3(v-if='row.last_note && row.last_note.id' style='font-size: 14px; word-break: break-word; white-space: pre-wrap; width: 280px;') {{ row.last_note.json.note }}
                .p-3.opacity-50.d-flex(style='width: 280px')
                  span {{row.last_note.username}}
                  span.ml-auto {{ row.last_note.created_at | datetime_dow }}
            td.td.border-left(v-if='!col.hidden_header' v-for='col in document.config.cols' :style='{width: (col.width || 200)+"px", maxWidth: (col.width || 200)+"px"}')
              .text-right(v-if='col.format == "number"') {{row.json[col.key] | number}}
              div(v-else-if='col.format == "date"')
                template(v-if='row.json[col.key] && row.json[col.key].date')
                  .d-flex.flex-wrap
                    span
                      span {{row.json[col.key].date | date}}
                      span.ml-1(v-show='row.json[col.key].use_time == "Y"') {{row.json[col.key].time | time}}
                    span.mx-1.opacity-50(v-show='row.json[col.key].use_end_date == "Y"') →
                    span(v-show='row.json[col.key].date != row.json[col.key].end_date')
                      span {{row.json[col.key].end_date | date}}
                      span.ml-1(v-show='row.json[col.key].use_time == "Y"') {{row.json[col.key].end_time | time}}
              div(v-else-if='col.format == "select"')
                template(v-if='row.json[col.key] && isArray(row.json[col.key])')
                  span.tag-item.rounded.mr-1.mb-1.d-inline-block(
                    v-if='tags_ready && document.config.cols_options[col.key] && document.config.cols_options[col.key][tag_id]'
                    v-for='tag_id in row.json[col.key]' :key='tag_id' :style='{backgroundColor: (document.config.cols_options[col.key][tag_id].color || "#e4e4e4")}'
                  ) {{document.config.cols_options[col.key][tag_id].name}}
              span(v-else style='margin: -0.5rem; min-height: 36px')
                input.form-control.input-cell(v-model='row.json[col.key]' @focus='edit_focus(row, col)' @blur='edit_blur(row, col)' :style='{marginTop: row.expand_top[col.key]}')
            td.border-left(v-if='document.config.show_header_card == "Y"' style='width: calc(50vw - 200px)')
              .d-flex.flex-wrap
                div.pr-2(v-for='col in document.config.cols' v-if='col.hidden_header' style='min-width: 120px; padding-bottom: 0.5rem')
                  small.text-muted.d-block {{col.label}}
                  div(v-if='col.format == "select"')
                    template(v-if='row.json[col.key] && isArray(row.json[col.key])')
                      span.tag-item.rounded.mr-1.mb-1.d-inline-block(
                        v-if='tags_ready && document.config.cols_options[col.key] && document.config.cols_options[col.key][tag_id]'
                        v-for='tag_id in row.json[col.key]' :key='tag_id' :style='{backgroundColor: (document.config.cols_options[col.key][tag_id].color || "#e4e4e4")}'
                      ) {{document.config.cols_options[col.key][tag_id].name}}

                  span.text-break(v-else) {{row.json[col.key] || '-'}}
            td.td.border-left(style='width: 30px')
          tr.border-top
            td.td-check
            td.td
              button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.text-primary.mr-1(type='button' @click='edit_save')
                b-icon-check.mr-1
                | 저장
              button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.text-secondary(type='button' @click='edit_cancel')
                b-icon-x.mr-1
                | 취소
              .pt-1
              button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.text-secondary(type='button' @click='create')
                b-icon-plus.mr-1
                | 추가


    .row.justify-content-center.align-items-center(v-if='document.id && +rows_count === 0')
      .col-6.async(:class='{done:(done)}')
        .mt-4
        h5.title 시작하기
        br
        button.btn.btn-default.text-dark.btn-lg.btn-block.text-left.border.shadow-sm.mr-2(type='button' @click='create')
          b-icon-textarea-t.text-info.mr-3(style='opacity: .5')
          | 새로 입력하기
        button.btn.btn-default.text-dark.btn-lg.btn-block.text-left.border.shadow-sm.mr-2(type='button' @click='$modal.show("excel")')
          b-icon-file-earmark-ruled.text-success.mr-3(style='opacity: .5')
          | CSV 엑셀파일 가져오기
        button.btn.btn-default.text-dark.btn-lg.btn-block.text-left.border.shadow-sm.mr-2(type='button' @click='open_db_modal')
          b-icon-server.text-warning.mr-3(style='opacity: .5')
          | DB 쿼리 가져오기

        div(style='padding-top: 150px')
    div(style='padding-top: 50px')
      button.btn.btn-primary.shadow.m-2.position-fixed(v-show='rows_selected_count' type='button' id='opt_more_table2' style='bottom: 0px; z-index: 11; border: solid 1px rgba(0,0,0,0.1)') {{rows_selected_count}}건 선택됨
      b-popover(ref='opt_more_table2' target='opt_more_table2' triggers='click' no-fade placement='right' custom-class='popover-dropdown popover-dropdown-sm')
        button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='remove_selected' v-show='query_deleted != "Y"')
          b-icon-trash.mr-2.opacity-50
          span 휴지통에 넣기
        button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='undo_remove_selected' v-show='query_deleted == "Y"')
          b-icon-reply.mr-2.opacity-50
          span 복구
        .border-bottom.opacity-50
        button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='copy')
          b-icon-clipboard-plus.mr-2.opacity-50
          span 내용 복사하기
        //- button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' :id='`opt_bulk_pipeline`')
        //-   span 파이프라인 추가

        //- b-popover(:ref='`opt_bulk_pipeline`' :target='`opt_bulk_pipeline`' triggers='click' placement='right' custom-class='popover-dropdown')
        //-   div(v-for='p in $store.state.pipelines' :id='`opt_bulk_pipeline_${p.id}`')
        //-     //- button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='add_pipeline(p)')
        //-     button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button')
        //-       span {{p.name}}
        //-     b-popover(:ref='`opt_bulk_pipeline_${p.id}`' :target='`opt_bulk_pipeline_${p.id}`' triggers='click' placement='right' custom-class='popover-dropdown')
        //-       div(v-for='s in p.config.status')
        //-         button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='add_pipeline(p, s)')
        //-           span {{s.name}}
        //- .border-bottom.opacity-50
    modal(name='record' width='1130px' :height='`auto`' :scrollable='true' draggable='.header-draggable'
      @before-close='record_before_close'
      transition='none'
      overlay-transition='none'
    )
      //- transition='record'
      //- style='padding: 3rem 0'
      //- :clickToClose='false'
      button.btn.btn-default.float-right.text-muted.rounded-0.bg-light(type='button' @click='$modal.hide("record")' style='font-size: 1.5rem'): b-icon-x
      //- button.btn.btn-default.float-right.text-muted.rounded-0.bg-light(type='button' @click='record_next()' style='font-size: 1.5rem'): b-icon-chevron-right
      //- button.btn.btn-default.float-right.text-muted.rounded-0.bg-light(type='button' @click='record_prev()' style='font-size: 1.5rem'): b-icon-chevron-left
      button.btn.btn-default.float-right.text-muted.rounded-0.bg-light(type='button' @click='record_next()' style='font-size: 1.5rem'): b-icon-chevron-up
      button.btn.btn-default.float-right.text-muted.rounded-0.bg-light(type='button' @click='record_prev()' style='font-size: 1.5rem'): b-icon-chevron-down

      //- div(style='height: 650px')
      div#modal1(
        v-b-tooltip.manual.bottom='`수정 완료후 창을 닫아주세요.`'
      )
        document-record(
          ref='document-record'
          :property='property'
          :document='document'
          :document_record_id='document_record_id'
          :lookup_candidates_by_value='lookup_candidates_by_value'
          @loaded='record_did_loaded'
          @updated='record_did_updated'
          @updated_note='record_did_updated'
          @updated_pipeline='record_did_updated'
          @closing='$modal.hide("record")'
          @editing='record_editing'
        )
        //- :record_id='record_id'
        //- :tags='tags'
        //- @tag_updated='record_tag_did_updated'

    modal(name='config-relation' width='500px' :height='`auto`' :scrollable='true'
      transition='record'
    )
      .p-4
        h5.mb-4.title 다른시트 선택

        .d-flex
          div.w-100.pr-1
            select.form-control(size=10 v-model='current_col.document_id')
              option(v-for='d in $store.state.documents' :value='d.id') {{d.name || '-'}}
          div.w-100
            select.form-control(
              v-if='current_col.document_id && $store.state.documents_by_id[current_col.document_id]'
              size=10 v-model='current_col.document_colkey'
            )
              option(v-for='col in $store.state.documents_by_id[current_col.document_id].config.cols' :value='col.key') {{col.label || '-'}}
        //- .mt-4
        .mt-1
        button.btn.btn-default.text-secondary(type='button' @click='clear_col_relation()') 선택취소
      //- pre {{current_col}}
      button.btn.btn-primary.btn-block.py-4.rounded-0(type='button' @click='save_col_relation()') 저장

</template>

<script>

import ExcelImport from '@/components/ExcelImport'
import DocumentRecord from '@/components/DocumentRecord'
import SearchFilter from "@/components/SearchFilter";
import {uniq, isArray, isString} from 'lodash'

import { customAlphabet } from 'nanoid'
const nanoid = customAlphabet('123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz', 5)

import draggable from 'vuedraggable'
const xlsx = require('xlsx')
const moment = require('moment')

import CellTag from '@/components/CellTag'
import CellDate from '@/components/CellDate'
import CellNumber from '@/components/CellNumber'
import CellRelation from '@/components/CellRelation'

const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData("Text", text);

  }
  else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
          return document.execCommand("copy");  // Security exception may be thrown by some browsers.
      }
      catch (ex) {
          console.warn("Copy to clipboard failed.", ex);
          return false;
      }
      finally {
          document.body.removeChild(textarea);
      }
  }
}

export default {
  name: 'index',
  props: ['document_id', 'document_name', 'saved_filter_id', 'document_record_id'],
  components: {
    ExcelImport, DocumentRecord, SearchFilter,
    draggable,
    CellTag, CellDate, CellNumber, CellRelation,
  },
  computed: {
    session() {
      return this.$store.state.session
    },
    property() {
      return this.$store.state.property
    },
    tags_ready() {
      return this.document && this.document.config && this.document.config.cols_options
    },
  },
  watch: {
    '$store.state.documents'() {
      if (this.document.id || this.document.id == this.document_id) {
        return
      }
      this.load()
    },
    'document_id'() {
      this.rows_ready = false
      this.query_deleted = false
      this.sort_loaded = false
      this.sort = 'ASC'
      this.sortby = 'created_at'
      this.load()
    },
    'document_record_id'() {
      this.open_modal()
    },
  },
  mounted() {
    this.load()
    setTimeout(() => {
      // this.open_filter_mod @keydown.down.once='focus_scroll()' al()
      // this.$modal.show("excel")
      // this.$refs['frame-scroll']?.focus()
      this.$refs['frame-input']?.focus()

      // this.current_col = this.document.config.cols.filter(e => e.format == 'relation')[0]
      // this.$modal.show("config-relation")
    }, 300);
    window.addEventListener('keydown', this._event_copy)
    document.addEventListener('visibilitychange', this._handleVisibilityChange, false);
    window.addEventListener('beforeunload', this._handleBeforeUnload);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this._event_copy)
    document.removeEventListener('visibilitychange', this._handleVisibilityChange);
    window.removeEventListener('beforeunload', this._handleBeforeUnload);
  },
  data() {
    return {
      ENV: process.env,
      done: false,
      loading: false,
      adding: false,
      document: {},
      rows: [],
      rows_count: 0,
      rows_pageable_count: 0,
      rows_ready: false,
      rows_selected_count: 0,
      fills: Array.from('00000000000000000000000'),

      record_loaded: false,

      dropdown_group_record_active: false,

      filters: [],
      selected_filter: null,

      saved_filter: {},
      form_filter: {
        name: '',
        is_shared: 'N',
      },
      filter_changed: false,

      tags: [],
      tags_by_record_id: {},
      tags_count: null,

      min_height: '90vh',
      should_open_new_window: false,
      selected_all: false,

      sort: 'ASC',
      sortby: 'created_at',
      cols_by_key: {},
      cols_by_key_loaded: false,

      tag_selected_action: '',

      fullpage_record_id: null,
      scroll_stop: false,

      cols_ready: false,
      cols_primary: [],

      // document_record_id: null,

      drag: false,

      drag_options: {
        animation: 200,
        ghostClass: 'ghost',
      },
      cols_ordered: [],

      new_filter_name: '',
      current_filter: {},

      query_deleted: 'N',
      is_editing: false,
      is_editing_focusing: false,

      refresh_slack_when_visible: false,
      is_refreshing_slack: false,

      updating_format: false,
      record_is_editing: false,

      current_col: {},
      lookup_candidates: [],
      lookup_candidates_by_value: {},
    }
  },
  methods: {
    // col_grow(col) {
    //   const w = +col.width || 200
    //   this.$set(col, 'width', Math.min(400, w+50))
    // },
    // col_shrink(col) {
    //   const w = +col.width || 200
    //   this.$set(col, 'width', Math.max(200, w-50))
    // },
    col_left(col) {
      let l = null
      let r = null
      for (const idx in this.document.config.cols) {
        if (this.document.config.cols[idx].key == col.key) {
          l = +idx
          if (this.document.config.cols[l-1]) {
            r = l-1
          }
          break
        }
      }
      if (l === null || r === null) return
      const temp = this.document.config.cols[l]
      this.document.config.cols[l] = this.document.config.cols[r]
      this.document.config.cols[r] = temp
      this.$set(this.document.config.cols, '', '')
      this.save_document_col()
    },
    col_right(col) {
      let l = null
      let r = null
      for (const idx in this.document.config.cols) {
        if (this.document.config.cols[idx].key == col.key) {
          l = +idx
          if (this.document.config.cols[l+1]) {
            r = l+1
          }
          break
        }
      }
      if (l === null || r === null) return
      const temp = this.document.config.cols[l]
      this.document.config.cols[l] = this.document.config.cols[r]
      this.document.config.cols[r] = temp
      this.$set(this.document.config.cols, '', '')
      this.save_document_col()
    },
    clear_col_relation() {
      this.current_col.document_id = undefined
      this.current_col.document_colkey = undefined
      this.save_col_relation()
    },
    async save_col_relation() {
      this.loading = true
      this.$modal.hide("config-relation")

      this.document.config.cols = this.document.config.cols.map(e => {
        if (e.key == this.current_col.key) {
          return this.current_col
        }
        return e
      })

      await this.save_document_col()

      this.$root.$emit('bv::show::popover', `opt_field_table_${this.current_col.key}`)
      this.current_col = {}

      this.load_relation(0)
    },
    open_config_relation(col) {
      this.current_col = Object.assign({}, col)
      this.blur_popover()
      this.$modal.show("config-relation")
    },
    focus_scroll() {
      this.$refs['frame-scroll']?.focus()
    },
    record_next() {
      let id = null, idx
      for (const i in this.rows) {
        if (this.rows[i].id == this.document_record_id) {
          idx = +i - 1
          if (this.rows[idx]) {
            id = this.rows[idx].id
            break
          } else {
            break
          }
        }
      }
      console.log(idx, id)
      if (id !== null) this.open_record(this.rows[idx])
    },
    record_prev() {
      let id, idx
      for (const i in this.rows) {
        if (this.rows[i].id == this.document_record_id) {
          idx = +i + 1
          if (this.rows[idx]) {
            id = this.rows[idx].id
            break
          } else {
            break
          }
        }
      }
      console.log(idx, id)
      if (id && idx) this.open_record(this.rows[idx])
    },
    _handleBeforeUnload(e) {
      if (!this.record_is_editing) {
        return undefined
      }
      const confirmationMessage = '수정중인 페이지를 닫으시겠습니까?';
      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    },
    _handleVisibilityChange() {
      if (!document.hidden) {
        if (this.refresh_slack_when_visible) {
          console.log('refresh_slack_when_visible')
          setTimeout(() => {
            this.$store.dispatch('slack config', this.property.id)

            console.log('this.$store.state.slack_config.access_json.authorized_at', this.$store.state.slack_config?.access_json?.authorized_at)
            this.refresh_slack_when_visible = false
          }, 1000);

          setTimeout(() => {
            this.$store.dispatch('slack config', this.property.id)
          }, 3000);
        }
      }
    },
    async refresh_slack() {
      this.loading = true
      this.is_refreshing_slack = true
      try {
        const r = await this.$http.post(`/v1/property/${this.property.id}/slack_config/actions/refresh`)
        if (r?.data?.message != 'ok') throw new Error('슬랙 새고로침 요청실패')

        setTimeout(() => {
          this.$store.dispatch('slack config', this.property.id)
          this.loading = false
          this.is_refreshing_slack = false
        }, 3000);

      } catch (error) {
        alert(error)
      }
    },
    async connect_slack() {
      try {
        const r = await this.$http.post(`/v2/property/${this.property.subdomain}/create_slack`)
        if (r?.data?.message != 'ok') throw new Error('슬랙연결 활성화 필요')
        const uuid = r.data.uuid

        window.open(`${this.ENV.VUE_APP_BASE_API_URL}/v2/slack/oauth?state=${uuid}`)
        this.refresh_slack_when_visible = true
      } catch (error) {
        alert(error)
      }
      // :href='`${ENV.VUE_APP_BASE_API_URL}/v2/slack/oauth`'
    },
    toggle_config(name) {
      // this.document.config[name] = this.document.config[name] === "Y" ? "N" : "Y"
      this.$set(this.document.config, name, this.document.config[name] === "Y" ? "N" : "Y")
      this.save_document_col()
    },
    change_sort_col(colkey, sort) {
      if (this.sort == sort && this.sortby == colkey) {
        // deselect
        this.sort = 'ASC'
        this.sortby = 'created_at'
      } else {
        this.sort = sort
        this.sortby = colkey
      }

      this.load()

      const prev = '' + window.sessionStorage[`noitaler.${this.property.id}.customer.${this.document.id}.sort`]
      const next = JSON.stringify({ sort: this.sort, sortby: this.sortby })
      if (prev != next) {
        window.sessionStorage[`noitaler.${this.property.id}.customer.${this.document.id}.sort`] = next
      }
    },
    isArray() {
      return isArray(...arguments)
    },
    _event_copy(e) {
      if (e.code == 'KeyC' && e.metaKey === true) {
        this.copy()
      }
      return
    },
    async onPaste(e) {
      if (this.document_record_id) return false
      console.log('paste')

      const paste = (e.clipboardData || window.clipboardData).getData('text')

      console.log(paste)
      try {
        if (paste[0] != '{') return

        const json = JSON.parse(paste)

        if (json.version == 'copyConfigFromProject') {
          // if (this.document.config.cols.length) {
          //   throw new Error('이미 생성된 항목이 있는 경우')
          // }
          console.log('this.rows_count >> ', +this.rows_count, '<<')
          if (+this.rows_count !== 0) {
            throw new Error('이미 생성된 내역이 있는 경우')
          }
          if (!json.row) throw new Error('클립보드 내용 오류 row')
          if (!json.row.cols) throw new Error('클립보드 내용 오류 cols')
          if (!json.row.last_col_id) throw new Error('클립보드 내용 오류 cols')

          const preview = json.row.cols.map(e => `- ${e.label}`).join('\n')
          if (!confirm('원래있던 내용을 지우고 새로운 항목을 추가하시겠습니까?\n' + preview)) return false
          this.document.config = json.row
          this.document.config.order = null

          this.loading = true
          try {
            const document = Object.assign({}, this.document, {
              config: Object.assign({}, this.document.config)
            })
            const r = await this.$http.put(`/v1/property/${this.property.id}/views/documents/${this.document.id}`, document)
            if (r?.data?.message != 'ok') throw new Error('프로젝트설정 저장 실패')

            await this.$store.dispatch('documents', this.property.id)
          } catch (error) {
            this.$modal.show('dialog', {
              title: '실패',
              text: error.message,
            })
          }
          setTimeout(() => { this.loading = false }, 300)
        }
        else if (json.version == 'copyRecordsFromProject') {
          if (!json.rows) throw new Error('클립보드 내용 오류 rows')

          const cols_by_label = {}
          // const cols_options_by_name = {}
          for (const col of this.document.config.cols) {
            cols_by_label[col.label] = col
            cols_by_label[col.label].options_by_name = {}
            if (this.document.config.cols_options) {
              for (const col_opt_key in this.document.config.cols_options[col.key]) {
                const col_opt = this.document.config.cols_options[col.key][col_opt_key]
                cols_by_label[col.label].options_by_name[col_opt.name] = col_opt
              }
            }
          }

          for (const row of json.rows) {
            const o = {}
            for (const key in row) {
              const col = cols_by_label[key]
              if (!col) throw new Error('키 없음 ' + key)
              if (col.format == 'select') {
                o[col.key] = row[key].map(tagname => {
                  return col.options_by_name[tagname]?.id || ''
                })
              } else {
                o[col.key] = row[key]
              }
            }
            // console.log('>>', o)
            await this.$http.post(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records`, {
              row: o,
            })
          }
          this.load()
          this.$bvToast.toast(`추가했습니다. (${json.rows.length}건)`, {
            title: `알림`,
            variant: 'default',
            solid: true,
            toaster: 'b-toaster-bottom-right',
          })
        } else {
          throw new Error('클립보드 오류')
        }
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: `실패`,
          variant: 'default',
          solid: true,
          toaster: 'b-toaster-bottom-right',
        })
      }



              // // 아이템 있으면 에러
        // if (this.rows_count > 0) {
        //   throw new Error('레코드 있으면 에러')
        // }

      // 레이블 없을시 에러
      // doc column도 붙여넣기? 같으면 유지, 없으면 만들기?
    },
    copy_project() {
      try {
        copyToClipboard(JSON.stringify({
          version: "copyConfigFromProject",
          row: this.document.config,
        }))

        // doc column도 복사?
        this.$bvToast.toast(`프로젝트 설정을 복사했습니다.`, {
          title: `알림`,
          variant: 'default',
          solid: true,
          toaster: 'b-toaster-bottom-right',
        })
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: `실패`,
          variant: 'default',
          solid: true,
          toaster: 'b-toaster-bottom-right',
        })
      }
    },
    copy() {
      console.log('copy')
      if (this.rows_selected_count === 0) return

      try {
        // 레이블 중복시 에러
        const uniq_names = uniq(this.document.config.cols.map(e => e.label))
        if (uniq_names.length != this.document.config.cols.length) {
          throw new Error('레이블 중복시 에러')
        }

        // json Label: Value
        const rows = []
        for (const row of this.rows) {
          if (row.is_selected !== true) continue
          const o = {}
          for (const col of this.document.config.cols) {
            if (col.format == 'number') {
              o[col.label] = +row.json[col.key]
            }
            else if (col.format == 'select') {
              o[col.label] = [].concat(row.json[col.key] || []).map(tagid => {
                return this.document.config.cols_options[col.key][tagid]?.name || ''
              })
            }
            else {
              o[col.label] = String(row.json[col.key] || '')
            }
          }
          rows.push(o)
        }

        copyToClipboard(JSON.stringify({
          version: "copyRecordsFromProject",
          rows: rows.reverse(),
        }))

        // doc column도 복사?
        // this.$bvToast.toast(`복사했습니다. (${rows.length}건)`, {
        //   title: `알림`,
        //   variant: 'default',
        //   solid: true,
        //   toaster: 'b-toaster-bottom-right',
        // })

        this.$root.$emit('bv::hide::popover')
        // does focus for immediate paste
        this.$modal.show('dialog', {
          title: '알림',
          text: `복사했습니다. (${rows.length}건)`,
        })
        // this.$refs.project.focus()
        // this.$el.blur()
        // this.$el.focus()
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: `실패`,
          variant: 'default',
          solid: true,
          toaster: 'b-toaster-bottom-right',
        })
      }

    },
    async toggle_star() {
      // user wide로 제공하므로 일단 사용안함
      try {
        const menu_config = Object.assign({}, this.$store.state.property.menu_config)

        if (this.current_filter && this.current_filter.ts) {
          // in filter
          const starred_filter = menu_config.blocks.filter(e => {
            return e.document_id == this.document.id && e.filter_ts == this.current_filter.ts
          })
          if (starred_filter.length) {
            menu_config = menu_config.blocks.filter(e => {
              return !(e.document_id == this.document.id && e.filter_ts == this.current_filter.ts)
            })
          } else {
            menu_config.blocks.unshift({
              ts: Date.now(),
              document_id: this.document.id,
              filter_ts: this.current_filter.ts,
              format: 'link',
            })
          }
        } else {
          const starred_doc = menu_config.blocks.filter(e => {
            return e.document_id == this.document.id && !e.filter_ts
          })
          if (starred_doc.length) {
            menu_config = menu_config.blocks.filter(e => {
              return !(e.document_id == this.document.id && !e.filter_ts)
            })
          } else {
            menu_config.blocks.unshift({
              ts: Date.now(),
              document_id: this.document.id,
              format: 'link',
            })
          }
        }
        const r = await this.$http.put(`/v1/property/${this.property_id}/menu_config`, {
          menu_config,
        })
        if (r.data.message != 'ok') throw new Error(r.data.message || '즐겨찾기 저장 실패')
        await this.$store.dispatch('status flows', this.property.id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async undo_remove_selected() {
      if(!confirm(`${this.rows_selected_count}건을 복구하시겠습니까?`)) return false

      this.$root.$emit('bv::hide::popover')

      this.loading = true
      try {

        for (const row of this.rows) {
          if (row.is_selected === true) {
            await this.$http.post(`/v1/property/${row.property_id}/views/documents/${row.document_id}/records/row/${row.id}/actions/undo_delete`)
          }
        }

        // this.$emit('updated')
        // this.$router.push({
        //   path: `/property/${this.property.id}/customer/${this.document.id}/${this.$options.filters.encodeText(this.document.name)}`,
        // })
        this.$modal.show('dialog', {
          title: '알림',
          text: '복구했습니다.',
        })
        this.load()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.loading = false
    },
    async remove_selected() {
      if(!confirm(`${this.rows_selected_count}건을 삭제하시겠습니까?`)) return false

      this.$root.$emit('bv::hide::popover')

      this.loading = true
      try {

        for (const row of this.rows) {
          if (row.is_selected === true) {
            await this.$http.delete(`/v1/property/${row.property_id}/views/documents/${row.document_id}/records/row/${row.id}`)
          }
        }

        // this.$emit('updated')
        // this.$router.push({
        //   path: `/property/${this.property.id}/customer/${this.document.id}/${this.$options.filters.encodeText(this.document.name)}`,
        // })
        this.$modal.show('dialog', {
          title: '알림',
          text: '삭제했습니다.',
        })
        this.load()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.loading = false
    },
    select_all() {
      if (this.rows_selected_count == this.rows.length) {
        this.rows.forEach(e => e.is_selected = false)
      } else {
        this.rows.forEach(e => e.is_selected = true)
      }
      this.rows_selected_count = this.rows.filter(e => e.is_selected).length
      this.selected_all = this.rows_selected_count == this.rows.length
    },
    select_row(row) {
      row.is_selected = !row.is_selected
      this.rows_selected_count = this.rows.filter(e => e.is_selected).length
      this.selected_all = this.rows_selected_count == this.rows.length
    },
    toggle_show_deleted() {
      this.loading = true
      // this.done = false
      this.$root.$emit('bv::hide::popover')
      setTimeout(() => {
        this.query_deleted = this.query_deleted != 'Y' ? 'Y' : 'N'
        if (this.query_deleted === 'Y') {
          this.sortby = 'deleted_at'
        } else {
          this.sortby = 'created_at'
        }
        this.load()
      }, 300)
    },
    toggle_date_header(name) {
      const key = `show_header_${name}`
      this.document.config[key] = !this.document.config[key]
      this.save_document_col()
    },
    toggle_date_header_lazy(name) {
      // const key = `show_header_${name}`
      // this.document.config[key] = !this.document.config[key]
      this.save_document_col()
    },
    toggle_hidden_header_col(col) {
      col.hidden_header = !col.hidden_header
      this.save_document_col()
    },
    clear_current_filter() {
      this.$root.$emit('bv::hide::popover')
      setTimeout(() => {
        this.selected_filter = null
        this.current_filter = {}
        this.filters = []
        this.persist_local_filter()
        this.load()
      }, 100);
      return
    },
    delete_current_filter() {
      if (!confirm('정말로 필터를 삭제하시겠습니까?')) return false

      this.document.config.filters = this.document.config.filters.filter(e => {
        return e.ts != this.current_filter.ts
      })
      this.persist_filter()
    },
    save_current_filter() {
      this.persist_filter()
      this.$root.$emit('bv::hide::popover')
    },
    open_filter(f) {
      if (this.current_filter.ts == f.ts) {
        return this.clear_current_filter()
      }
      this.current_filter = f
      this.filters = f.filters

      this.persist_local_filter()
      this.load()
    },
    async persist_filter() {
      this.loading = true
      const document = Object.assign({}, this.document, {
        config: Object.assign({}, this.document.config, {
          filters: this.document.config.filters,
        })
      })
      const r = await this.$http.put(`/v1/property/${this.property.id}/views/documents/${this.document.id}`, document)
      if (r?.data?.message != 'ok') throw new Error('필터 저장 실패')
      setTimeout(() => { this.loading = false }, 100)
    },
    async save_filter() {
      this.loading = true
      try {
        if (!this.document.config.filters) {
          this.document.config.filters = []
        }
        const current_filter = {
          name: this.new_filter_name,
          filters: this.filters,
          ts: Date.now(),
        }
        this.document.config.filters.push(current_filter)
        this.new_filter_name = ''
        this.current_filter = current_filter

        this.persist_filter()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.loading = false
    },
    persist_local_filter() {
      const prev = '' + window.sessionStorage[`noitaler.${this.property.id}.customer.${this.document.id}.filter`]
      const next = JSON.stringify(Object.assign({}, this.current_filter, {
        filters: this.filters,
      }))
      if (prev != next) {
        this.filter_changed = true
        window.sessionStorage[`noitaler.${this.property.id}.customer.${this.document.id}.filter`] = next
      }
    },
    open_selected_filter_modal(item) {
      this.selected_filter = item
      this.$modal.show('filter')
    },
    or_filter(item) {
      const subitem = Object.assign({}, {
        sub_or: undefined,
        parent_ts: item.ts,
        ts: Date.now(),
      })
      this.filters = this.filters.filter(e => {
        if (+e.ts == +item.ts) {
          if (!item.sub_or) item.sub_or = []

          item.sub_or.push(subitem)
        }
        return e
      })
      this.open_selected_filter_modal(subitem)
      this.persist_local_filter()
    },
    open_filter_modal() {
      this.selected_filter = null
      this.$modal.show('filter')
    },
    clear_filter(item) {
      if (item.parent_ts) {
        this.filters = this.filters.filter(e => {
          if (+e.ts != +item.parent_ts) return true

          if (e.sub_or && e.sub_or.length) {
            e.sub_or = e.sub_or.filter(l => {
              return +l.ts != item.ts
            })
          }
          return true
        })
      } else {
        this.filters = this.filters.filter(e => {
          return +e.ts != +item.ts
        })
      }
      this.selected_filter = null
      if (this.filters.length == 0) {
        this.current_filter = {}
      }
      this.persist_local_filter()
      this.load()
      return
    },
    filter_did_updated(item) {
      console.log('filter_did_updated', item)
      if (item === 'dismiss') {
        // dismiss
        return this.$modal.hide('filter')
      }
      if (item === 'clear') {
        this.$modal.hide('filter')
        return this.clear_filter(this.selected_filter)
      }
      this.$modal.hide('filter')

      if (item.parent_ts) {
        this.filters = this.filters.map(e => {
          if (+e.ts == +item.parent_ts) {
            console.log('found ! ', e.sub_or)
            if(e.sub_or?.length) {
              e.sub_or = e.sub_or.map(l => {
                console.log('check ? ', l.ts, item.ts)
                if (+l.ts == +item.ts) {
                  console.log('check ! ')
                  return item
                }
                return l
              })
            }
          }
          return e
        })
      } else {
        const need_update = this.filters.filter(e => e.ts == item.ts).length
        if (need_update) {
          this.filters = this.filters.map(e => {
            if (e.ts == item.ts) {
              return item
            }
            return e
          })
        } else {
          this.filters.push(item)
        }
      }

      this.persist_local_filter()
      this.load()
    },
    export_file(output_format) {
      const wb = xlsx.utils.book_new()

      // const ws = xlsx.utils.table_to_sheet(this.$refs.table)

      // const ws = xlsx.utils.json_to_sheet(this.rows.map(e => e.json), {header:this.document.config.cols.map(e => e.key), skipHeader:false});
      // const pre = ['ID', 'Created At', 'Updated At']
      const pre = []
      const ws = xlsx.utils.aoa_to_sheet(
        [
          pre.concat(
            this.document.config.cols.map(e => {
              return e.label
            })
          ),
          ...this.rows.filter(row => {
            if (this.rows_selected_count) {
              return row.is_selected
            } else {
              return true
            }
          }).map(row => {
            // const created_at = this.$options.filters.datetime(row.created_at)
            // const updated_at = row.updated_at ? this.$options.filters.datetime(row.updated_at) : ''
            // return [row.id, created_at, updated_at].concat(
            return this.document.config.cols.map(col => row.json[col.key])
          }),
        ],
      )

      xlsx.utils.book_append_sheet(wb, ws, this.document.name)
      if (output_format == 'csv') {
        xlsx.writeFile(wb, `noitaler-export-${moment().format('YYYY.MM.DD') + '-' + Date.now()}.csv`)
        return
      }
      if (output_format == 'xlsx') {
        xlsx.writeFile(wb, `noitaler-export-${moment().format('YYYY.MM.DD') + '-' + Date.now()}.xlsx`)
        return
      }
      if (output_format == 'html') {
        xlsx.writeFile(wb, `noitaler-export-${moment().format('YYYY.MM.DD') + '-' + Date.now()}.html`, {
          editable: true,
          header: '<html><head><style>* { font-size: 14px; font-family: sans-serif } </style></head><body>',
        })
        return
      }
    },
    async drag_end() {
      try {
        this.drag = false
        this.save_document_col()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    blur_popover() {
      // console.log('blur_popover')
      this.$root.$emit("bv::hide::popover")
    },
    async update_format_document_col(col, value, opt) {
      try {
        this.updating_format = true

        // disable dedup
        if (value == '@고객아이디') {
          const exists = this.document.config.cols.filter(e => e.format == value)
          if (exists.length) {
            throw new Error(`[${exists[0].label}] 항목이 이미 [@고객아이디]로 지정되어 있습니다.`)
          }
        }
        if (value == '@고객연락처') {
          const exists = this.document.config.cols.filter(e => e.format == value)
          if (exists.length) {
            throw new Error(`[${exists[0].label}] 항목이 이미 [@고객전화번호]로 지정되어 있습니다.`)
          }
        }
        if (value == '@고객이메일') {
          const exists = this.document.config.cols.filter(e => e.format == value)
          if (exists.length) {
            throw new Error(`[${exists[0].label}] 항목이 이미 [@고객이메일]로 지정되어 있습니다.`)
          }
        }
        if (value == '@고객이름') {
          const exists = this.document.config.cols.filter(e => e.format == value)
          if (exists.length) {
            throw new Error(`[${exists[0].label}] 항목이 이미 [@고객이름]으로 지정되어 있습니다.`)
          }
        }

        if (value == 'select') {
          if (opt == 'max=1') {
            // col.max = 1
            this.$set(col, 'max', 1)
          } else {
            // col.max = 0
            this.$set(col, 'max', 0)
          }
        }
        if (col.format != 'select' && value == 'select') {
          col.format = value
          await this.save_document_col()
          this.loading = true
          await this.$http.post(`/v1/property/${this.property.id}/views/documents/${this.document.id}/actions/fill_cols_options`)
          setTimeout(() => {
            // this.$store.dispatch('documents', this.property_id)
            this.updating_format = false
            this.loading = false
          }, 3000);
          return
        }
        // if (col.format == 'text' && value == 'select') {
        //   if (!confirm(`텍스트를 태그로 바꾸는 경우 콤마(,) 기준으로 변환됩니다. 계속할까요?`)) return false

        //   this.loading = true
        //   const r = await this.$http.post(`/v1/property/${this.property.id}/views/documents/${this.document.id}/actions/convert_format`, {
        //     col,
        //     target_format: value,
        //   })
        //   if (r?.data?.message != 'ok') throw new Error('데이터 변환 실패')
        //   await this.$store.dispatch('documents', this.property.id)
        //   this.load()
        // }
        // else if (col.format == 'select' && value == 'text') {
        //   if (!confirm(`태그를 텍스트 바꾸는 경우 콤마(,) 기준으로 변환됩니다. 계속할까요?`)) return false

        //   this.loading = true
        //   const r = await this.$http.post(`/v1/property/${this.property.id}/views/documents/${this.document.id}/actions/convert_format`, {
        //     col,
        //     target_format: value,
        //   })
        //   if (r?.data?.message != 'ok') throw new Error('데이터 변환 실패')
        //   await this.$store.dispatch('documents', this.property.id)
        //   this.load()
        // }
        // else if (col.format == 'select' && value == 'select') {
        //   this.save_document_col()
        // }
        // else if (col.format == 'select') {
        //   throw new Error('미지원')
        // }
        // else if (value == 'select') {
        //   throw new Error('미지원')
        // }
        // else {
        //   col.format = value
        //   this.save_document_col()
        // }
        col.format = value
        this.save_document_col()

        // if (col.format == 'relation') {
        //   this.current_col = col
        //   this.$modal.show('config-relation')
        // }
      } catch (error) {
        this.loading = false
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.updating_format = false
    },
    async save_document_col() {
      this.loading = true
      try {
        const document = Object.assign({}, this.document, {
          config: Object.assign({}, this.document.config, {
            cols: this.document.config.cols,
          })
        })
        const r = await this.$http.put(`/v1/property/${this.property.id}/views/documents/${this.document.id}`, document)
        if (r?.data?.message != 'ok') throw new Error('항목 설정 실패: ' + r?.data?.message)

        await this.$store.dispatch('documents', this.property.id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      setTimeout(() => { this.loading = false }, 300)
    },
    add_document_col() {
      const key = 'd' + nanoid()

      this.document.config.last_col_id++
      this.document.config.cols.push({
        key,
        label: 'Property',
        format: 'text',
      })
      this.save_document_col()
      setTimeout(() => {
        this.$root.$emit('bv::show::popover', `opt_field_table_${key}`)
        this.$refs[`opt_field_input_table_${key}`][0].focus()
      }, 300)
    },
    delete_document_col(col) {
      if (!confirm(`[${col.label}] 항목을 삭제하시겠습니까?`)) return false
      this.document.config.cols = this.document.config.cols.filter(e => {
        return e.key != col.key
      })
      this.save_document_col()
    },

    async create() {
      this.loading = true
      try {
        const r = await this.$http.post(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records`, {
          row: {},
        })
        const document_record_id = r.data.row_id
        if (this.is_editing) {
          // this.load('append')
          const r2 = await this.$http.get(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/row/${document_record_id}`)
          this.rows.push(this.record_json_dto(r2.data.row, null, null))
        } else {
          this.$router.push({
            name: 'document.view.record',
            params: {
              document_record_id,
            }
          })
          this.load()
        }
        this.loading = false
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
        setTimeout(() => { this.loading = false }, 300)
      }
    },
    open_modal() {
      if (this.document_record_id) {
        this.$modal.show('record')
      } else {
        this.$modal.hide('record')
      }
    },
    record_did_loaded() {
      this.record_loaded = true
      // setTimeout(() => { this.loading = false }, 300)
    },
    record_did_updated(row_id) {
      console.log('reload from record_did_updated', row_id)
      console.log('TODO: reload row')
      this.load_one(row_id)
      // this.load()
    },
    record_editing(is_editing) {
      this.record_is_editing = is_editing
    },
    record_before_close(event) {
      // event.cancel()
      console.log('>>>>>> record_before_close ', this.record_is_editing, this.document_record_id)

      if (this.document_record_id) {
        if (this.record_is_editing) {
          this.$refs['document-record']?._dismiss()
          return event.cancel()
        }
        // this.document_record_id = null
        // this.open_modal()
        this.$router.push({
          name: 'document.view',
        })
        this.update_title()
      }
    },
    open_record_new(row) {
      const r = this.$router.resolve({
        name: 'document.view.record',
        params: {
          document_record_id: row.id,
        }
      })
      window.open(r.href, '_blank')
      return false
    },
    open_record(row) {
      this.$router.push({
        name: 'document.view.record',
        params: {
          document_record_id: row.id,
        }
      })
    },
    async save_document_name() {
      try {
        this.loading = true
        const r = await this.$http.put(`/v1/property/${this.property.id}/views/documents/${this.document.id}`, this.document)
        if (r?.data?.message != 'ok') throw new Error('프로젝트 저장 실패')

        await this.$store.dispatch('documents', this.property.id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      setTimeout(() => {
        this.loading = false
      }, 300);
    },
    async delete_document() {
      try {
        if(!confirm('정말로 삭제하시나요?')) return true

        const r = await this.$http.delete(`/v1/property/${this.property.id}/views/documents/${this.document.id}`)
        if (r?.data?.message != 'ok') throw new Error('프로젝트 삭제 실패')

        await this.$store.dispatch('documents', this.property.id)
        setTimeout(() => {
          this.$store.dispatch('pipelines', this.property.id)
        }, 1000);

        this.$modal.show('dialog', {
          title: '알림',
          text: '삭제했습니다.',
        })
        this.$router.push({
          name: 'layout',
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    did_saved_excel() {
      this.$modal.hide('excel')
      this.$modal.show('dialog', {
        title: '알림',
        text: '추가했습니다.',
      })
      this.load()
    },
    open_db_modal() {
      this.$modal.show('dialog', {
        title: '알림',
        text: '준비중입니다. <p class="pt-4 font-weight-bold text-muted">사용하고 계신 데이터베이스 타입(MySQL, PostgreSQL, SQL Server등)을 오른쪽 하단으로 알려주시면 먼저 연락드리겠습니다.</p>',
      })
    },
    username(id) {
      return this.$store.state.users_by_id[id]?.name || '(이름없음)'
    },
    open_edit() {
      this.is_editing = true
    },
    async edit_save() {
      const changed = this.rows.map((e, i) => { return [e, i] } ).filter(e => {
        console.log(e)
        return e[0].is_edited
      })
      if (changed.length === 0) {
        return this.edit_cancel()
      }
      this.loading = true
      try {
        console.log('try saving')

        for (const [row, i] of changed) {
          const r = await this.$http.put(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/row/${row.id}`, {
            row: row.json,
          })
          if (r?.data?.message != 'ok') throw new Error('저장 실패. ' + r.data.message)
          this.rows[i]._original_json = JSON.stringify(row.json)
          this.rows[i].is_edited = false
        }
        // this.edit_cancel()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      setTimeout(() => { this.loading = false }, 300)
    },
    edit_cancel() {
      this.is_editing = false
      this.load()
    },
    edit_focus(row, col) {
      this.is_editing_focusing = true
      row.expand_top[col.key] = -this.$refs[`frame-scroll`].scrollTop + 'px'
    },
    edit_blur(row, col) {
      this.is_editing_focusing = false
      if (row._original_json != JSON.stringify(row.json)) {
        console.log(row._original_json, '<>', JSON.stringify(row.json))
        row.is_edited = true
      } else {
        row.is_edited = false
      }
      row.expand_top[col.key] = '0px'
    },
    record_json_dto(e, rows_last_note_by, rows_pipeline_by) {
      // e.json = Object.freeze(JSON.parse(e.json) || {})
      if (isString(e.json)) {
        e.json = JSON.parse(e.json) || {}
      }
      e._original_json = JSON.stringify(e.json)
      e.is_selected = false
      if (rows_last_note_by) {
        e.last_note = Object.freeze(rows_last_note_by[e.id] || {})
      } else {
        e.last_note = {}
      }
      if (rows_pipeline_by) {
        e.pipelines = Object.freeze(rows_pipeline_by[e.id] || [])
      } else {
        e.pipelines = {}
      }
      e.is_edited = false
      e.expand_top = {}
      e._key = e.id + '_' + e.updated_at
      return e
    },
    update_title() {
      setTimeout(() => {
        const text = []
        text.push(`${this.document.name}`)
        if (!this.document_record_id) {
          document.title = text.join(' ')
        }
      }, 100);
    },
    async load(mode = '') {
      if (!this.$store.state.documents || this.$store.state.documents.length == 0) return
      if (!this.$store.state.property) return

      this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      if (!this.document.id) {
        this.$modal.show('dialog', {
          title: '알림',
          text: '해당 프로젝트를 찾지 못했습니다.',
        })
        return
      }
      try {
        console.log('saved_filter_id', this.saved_filter_id)
        console.log('saved_filter', this.saved_filter, this.saved_filter.id)

        // load local filter
        const saved_filter = window?.sessionStorage[`noitaler.${this.property.id}.customer.${this.document.id}.filter`]
        if (saved_filter) {
          try {
            const current_filter = JSON.parse(saved_filter)
            // console.log(current_filter)
            this.current_filter = current_filter
            this.filters = current_filter.filters
          } catch (error) {
            console.log('failed to parse', saved_filter)
          }
        } else {
          this.filters = []
          this.current_filter = {}
        }

        if (!this.saved_filter.id) {
          this.$emit('filter_updated', {})
        }

        if (!this.sort_loaded) {
          const sort = window?.sessionStorage[`noitaler.${this.property.id}.customer.${this.document.id}.sort`]
          if (sort) {
            try {
              const opt = JSON.parse(sort)
              this.sort = opt.sort
              this.sortby = opt.sortby
              this.sort_loaded = true
            } catch (error) {
              console.log('failed to parse', sort)
            }
          }
        }


        this.loading = true
        const r = await this.$http.get(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records`, {
          params: {
            filters: JSON.stringify(this.filters),
            sort: this.sort,
            sortby: this.sortby,
            deleted: this.query_deleted === 'Y' ? 'Y' : undefined,
            last_note: this.document.config.show_header_last_note === true ? 'Y' : 'N',
            pipeline: this.document.config.show_header_pipeline === true ? 'Y' : 'N',
            name: this.document.config.show_header_name === true ? 'Y' : 'N',
          }
        })
        const m = r?.data?.message || ''
        if (m != 'ok') {
          if (m.includes('(filter)')) {
            // reset filter
            window.sessionStorage[`noitaler.${this.property.id}.customer.${this.document.id}.filter`] = undefined
          }
          // window.sessionStorage[`noitaler.${this.property.id}.customer.${this.document.id}.filter`] = undefined
          throw new Error('불러오기 실패: '+ r?.data?.message)
        }
        const rows_last_note = r.data.rows_last_note
        const rows_last_note_by = {}
        for (const r of rows_last_note) {
          r.json = JSON.parse(r.json) || {}
          r.username = this.username(r.user_id)
          rows_last_note_by[r.record_id] = r
        }
        const rows_pipeline = r.data.rows_pipeline
        const rows_pipeline_by = {}
        for (const r of rows_pipeline) {
          if (!rows_pipeline_by[r.record_id]) {
            rows_pipeline_by[r.record_id] = []
          }
          rows_pipeline_by[r.record_id].push(r)
        }
        this.rows = r.data.rows.map(e => {
          return this.record_json_dto(e, rows_last_note_by, rows_pipeline_by)
        })
        this.rows_count = r.data.rows_count
        this.rows_pagable_count = r.data.rows_pagable_count
        this.rows_selected_count = 0
        this.selected_all = false
        this.rows_ready = true

        this.cols_ready = true
        const cols_by_key = {}

        this.document.config.cols = this.document.config.cols.map(e => {
          if (!e.width) e.width = 200
          return e
        })

        for (const col of this.document.config.cols) {
          if (!this.cols_by_key_loaded) {
            cols_by_key[col.key] = col
          }
        }
        if (!this.cols_by_key_loaded) {
          this.cols_by_key = cols_by_key
          this.cols_by_key_loaded = true
        }

        this.saving_text = '저장'
        this.done = true

        // this.document_record_id = this.document_record_id
        this.open_modal()

        // this.open_filter_modal()
        this.update_title()

        if (!this.$store.state.slack_config) {
          this.$store.dispatch('slack config', this.property.id)
        }
        this.load_relation(0)
      } catch (error) {
        console.log(error)
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.loading = false
    },
    async load_one(row_id) {
      try {
        this.loading = true
        const r = await this.$http.get(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records`, {
          params: {
            filters: JSON.stringify(this.filters),
            sort: this.sort,
            sortby: this.sortby,
            deleted: this.query_deleted === 'Y' ? 'Y' : undefined,
            last_note: this.document.config.show_header_last_note === true ? 'Y' : 'N',
            pipeline: this.document.config.show_header_pipeline === true ? 'Y' : 'N',
            id: row_id,
          }
        })
        const m = r?.data?.message || ''
        if (m != 'ok') {
          if (m.includes('(filter)')) {
            // reset filter
            window.sessionStorage[`noitaler.${this.property.id}.customer.${this.document.id}.filter`] = undefined
          }
          // window.sessionStorage[`noitaler.${this.property.id}.customer.${this.document.id}.filter`] = undefined
          throw new Error('불러오기 실패: '+ r?.data?.message)
        }
        const rows_last_note = r.data.rows_last_note
        const rows_last_note_by = {}
        for (const r of rows_last_note) {
          r.json = JSON.parse(r.json) || {}
          r.username = this.username(r.user_id)
          rows_last_note_by[r.record_id] = r
        }
        const rows_pipeline = r.data.rows_pipeline
        const rows_pipeline_by = {}
        for (const r of rows_pipeline) {
          if (!rows_pipeline_by[r.record_id]) {
            rows_pipeline_by[r.record_id] = []
          }
          rows_pipeline_by[r.record_id].push(r)
        }

        const row = r.data.rows[0]
        if (row && row.id) {
          for (const i in this.rows) {
            if (this.rows[i].id != row.id) continue
            Object.assign(this.rows[i], this.record_json_dto(row, rows_last_note_by, rows_pipeline_by))
          }
        }

        // this.rows_count = r.data.rows_count
        // this.rows_selected_count = 0
        this.selected_all = false
        this.rows_ready = true

        this.cols_ready = true
        this.done = true

        this.load_relation(0)
      } catch (error) {
        console.log(error)
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.loading = false
    },
    async load_more() {
      try {
        this.loading = true
        const r = await this.$http.get(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records`, {
          params: {
            filters: JSON.stringify(this.filters),
            sort: this.sort,
            sortby: this.sortby,
            deleted: this.query_deleted === 'Y' ? 'Y' : undefined,
            last_note: this.document.config.show_header_last_note === true ? 'Y' : 'N',
            pipeline: this.document.config.show_header_pipeline === true ? 'Y' : 'N',
            name: this.document.config.show_header_name === true ? 'Y' : 'N',
            offset: this.rows.length,
            limit: 100,
          }
        })
        const m = r?.data?.message || ''
        if (m != 'ok') {
          if (m.includes('(filter)')) {
            // reset filter
            window.sessionStorage[`noitaler.${this.property.id}.customer.${this.document.id}.filter`] = undefined
          }
          // window.sessionStorage[`noitaler.${this.property.id}.customer.${this.document.id}.filter`] = undefined
          throw new Error('불러오기 실패: '+ r?.data?.message)
        }
        const rows_last_note = r.data.rows_last_note
        const rows_last_note_by = {}
        for (const r of rows_last_note) {
          r.json = JSON.parse(r.json) || {}
          r.username = this.username(r.user_id)
          rows_last_note_by[r.record_id] = r
        }
        const rows_pipeline = r.data.rows_pipeline
        const rows_pipeline_by = {}
        for (const r of rows_pipeline) {
          if (!rows_pipeline_by[r.record_id]) {
            rows_pipeline_by[r.record_id] = []
          }
          rows_pipeline_by[r.record_id].push(r)
        }
        const rows = r.data.rows.map(e => {
          return this.record_json_dto(e, rows_last_note_by, rows_pipeline_by)
        })
        this.rows.push(...rows)

        this.rows_count = r.data.rows_count
        this.rows_pagable_count = r.data.rows_pagable_count

        this.load_relation(this.rows.length-rows.length)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.loading = false
    },
    async load_relation(offset = 0) {
      this.loading = true
      try {
        const eid_col = this.document.config.cols.filter(e => e.format == 'relation')[0]
        console.log('eid_col', eid_col)
        if (!eid_col || !eid_col.document_id || !eid_col.document_colkey) {
          this.lookup_candidates = []
          this.lookup_candidates_by_value = {}
          return
        }


        const r = await this.$http.get(`/v1/property/${this.property.id}/views/documents/${eid_col.document_id}/find-lookups`, {
          params: {
            // document_id: eid_col.document_id,
            colkey: eid_col.document_colkey,
          }
        })
        const m = r?.data?.message || ''
        if (m != 'ok') {
          throw new Error('다른시트 내용 불러오기 실패: '+ r?.data?.message)
        }
        const lookup_candidates = r.data.rows
        const lookup_candidates_by_value = {}
        for (const e of lookup_candidates) {
          if (!lookup_candidates_by_value[e.value]) {
            lookup_candidates_by_value[e.value] = []
          }
          lookup_candidates_by_value[e.value].push(e)
        }

        this.lookup_candidates = lookup_candidates
        this.lookup_candidates_by_value = lookup_candidates_by_value
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.loading = false
    },
  },
}
</script>
